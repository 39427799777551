const SET_CITIES = 'SET_CITIES'
const SET_SSR = 'SET_SSR'

export type State = {
  ssr: boolean
  cities: any[]
}

export const allCities = {
  state: () => ({
    ssr: false,
    cities: [],
  }),
  mutations: {
    [SET_CITIES]: (state: State, cities: any[]) =>
      (state.cities = cities),
    [SET_SSR]: (state: State, ssr: boolean) =>
      (state.ssr = ssr),
  },
  actions: {
    storeCities({ commit }, cities: any[]) {
      commit(SET_CITIES, cities)
    },
    setSSR({ commit }, ssr: boolean) {
      commit(SET_SSR, ssr)
    },
  },
}
