import { Collection } from 'collect.js'
import ProductResource from '@/resources/ProductResource'

class ProductCollection extends Collection {
  make(items) {
    if (!items) return
    return super.make(items.map((product) => new ProductResource(product)))
  }
}

export default ProductCollection
