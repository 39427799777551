import { Capacitor } from '@capacitor/core'
import { createI18n } from '@/i18n'
import { getContext } from '@/plugins/globals/segment/lib/helpers'
import { formatProducts, limitCartLength } from '@/plugins/globals/segment/lib/helpers'

// Matches images from the establishment data to cart items
// We can use these images in the abandoned cart emails (CustomerIO)
const addImagesToBasketItems = (
  items,
  { productSets }
) => {
  const products = productSets.reduce(
    (accumulative, { products }) => [
      ...accumulative,
      ...products,
    ],
    []
  )

  return items.map((item) => {
    const product = products.find(({ id }) => id === item.product.id)

    if (!product) return item

    // This iterator works only on staging / production
    // We need the library id in CustomerIO to get the image
    // This id is not available on dev
    const iterator = product.images?.cover?.sources?.small.url.matchAll(
      /library\/(\d+)\/conversions\/(.+)-sm(\..+)/g
    )

    if (!iterator) return item

    const [spread] = [...iterator]

    if (!spread) return item

    const [_fullMatch, libraryId, name, extension] = spread

    return {
      ...item,
      img: {
        n: name,
        e: extension,
        i: libraryId,
      },
    }
  })
}

export default class SegmentApi {
  /**
   * @param analyticsBrowser
   * @param store
   */
  constructor(analyticsBrowser, store) {
    this.analyticsBrowser = analyticsBrowser
    this.store = store
  }

  /**
   * @param name
   * @param properties
   *
   * @returns {*}
   */
  page(name, properties = {}) {
    if (Capacitor.isNativePlatform()) {
      return this.analyticsBrowser.screen(name, properties, {
        context: getContext(),
      })
    }

    return this.analyticsBrowser.page(name, properties, {
      context: getContext(),
    })
  }

  /**
   * @param userId
   * @param properties
   *
   * @returns {boolean}
   */
  identify(userId, properties = {}, options = { includeCart: false }) {
    const location = this.store.getters['session/location']
    const user = this.store.getters['user/user']
    userId = userId || user.id

    if (options.includeCart) {
      // add cart-info on identify to add content in abandoned cart emails
      const establishment = this.store.getters['cart/establishment']
      const basketItems = addImagesToBasketItems(
        this.store.getters['cart/basketItems'],
        establishment
      )

      const formattedProducts = limitCartLength(
        formatProducts(basketItems?.items, establishment, true)
      )
      properties.cart = {
        products: formattedProducts,
        extra: basketItems.items.length - formattedProducts.length,
      }
    }

    const i18n = createI18n()

    const extendedProperties = {
      userType: 'Customer',
      language: i18n.locale,
      name: user.name,
      phone: user.phone,
      email: user.email,
      birthday: user.birthday,
      address: {
        street: location?.street,
        city: location?.city,
        postalCode: location?.zipcode,
        country: 'Nederland',
      },
      ...properties,
    }

    if (userId) {
      // als segment id niet gelijk is aan user id dan resetten
      this.analyticsBrowser.user().then((user) => {
        // reset if current userId is different from segment's stored user id
        const segmentUserId = user.id()
        if (segmentUserId && userId !== segmentUserId) {
          this.track('User Switched')
          this.analyticsBrowser.reset()
        }

        // identify user
        return this.analyticsBrowser.identify(userId, extendedProperties, {
          context: getContext(),
        })
      })
    } else {
      return this.analyticsBrowser.identify(extendedProperties, {
        context: getContext(),
      })
    }
  }

  /**
   * @param eventName
   * @param properties
   * @param eventExtras
   *
   * @returns {*}
   */
  track(eventName, properties, eventExtras) {
    if (!eventName) return

    return this.analyticsBrowser.track(eventName, properties, {
      context: getContext(),
      ...eventExtras,
    })
  }

  /**
   * @returns {*}
   */
  reset() {
    return this.analyticsBrowser.reset()
  }
}
