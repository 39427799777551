import { inject } from 'vue'

const AXIOS_SYMBOL = Symbol()

export function provideAxios(app, axios) {
  app.provide(AXIOS_SYMBOL, axios)
}

export function useAxios() {
  return inject(AXIOS_SYMBOL)
}
