import { Category } from '@/http/models/Category'
import { City } from '@/http/models/City'

export type Point = {
  x: number
  y: number
}

export type State = {
  ssr: boolean
  city: object | null
  items: object[] | null
  currentPage: number
  scrollPosition: Point
  categories: object[] | null
  sortingKeys: string[] | null
  searchQuery: string | null
}

const SET_SSR = 'SET_SSR'
const SET_CITY = 'SET_CITY'
const SET_ITEMS = 'SET_ITEMS'
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_SORTING_KEYS = 'SET_SORTING_KEYS'
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export const marketplace = {
  state: () => ({
    ssr: false,
    city: null,
    items: null,
    currentPage: 1,
    scrollPosition: { x: 0, y: 0 },
    categories: null,
    sortingKeys: null,
    searchQuery: null,
  }),
  actions: {
    setSSR({ commit }, ssr: boolean) {
      commit(SET_SSR, ssr)
    },
    setCurrentCity({ commit }, city: object) {
      commit(SET_CITY, city)
    },
    setItems({ commit }, items: object[] | null) {
      commit(SET_ITEMS, items)
    },
    setCategories({ commit }, items: object[]) {
      commit(SET_CATEGORIES, items)
    },
    setSortingKeys({ commit }, sortingKeys: string[]) {
      commit(SET_SORTING_KEYS, sortingKeys)
    },
    storeSearchQuery({ commit }, searchQuery: string | null) {
      commit(SET_SEARCH_QUERY, searchQuery)
    },
    setCurrentPage({ commit }, currentPage: number) {
      commit(SET_CURRENT_PAGE, currentPage)
    },
    setScrollPosition({ commit }, scrollPosition: Point) {
      commit(SET_SCROLL_POSITION, scrollPosition)
    }
  },
  mutations: {
    [SET_CITY]: (state: State, city: object) => (state.city = city),
    [SET_ITEMS]: (state: State, items: object[]) => (state.items = items),
    [SET_CATEGORIES]: (state: State, categories: object[]) => (state.categories = categories),
    [SET_SORTING_KEYS]: (state: State, sortingKeys: string[]) => (state.sortingKeys = sortingKeys),
    [SET_SSR]: (state: State, ssr: boolean) => (state.ssr = ssr),
    [SET_SEARCH_QUERY]: (state: State, searchQuery: string | null) => (state.searchQuery = searchQuery),
    [SET_CURRENT_PAGE]: (state: State, currentPage: number) => (state.currentPage = currentPage),
    [SET_SCROLL_POSITION]: (state: State, scrollPosition: Point) => (state.scrollPosition = scrollPosition)
  },
  getters: {
    city: (state: State) => state.city !== null ? new City(state.city) : null,
    // transform happens in OfferingGrid.vue
    items: (state: State) => state.items,
    categories: (state: State) => state.categories !== null ? state.categories.map(category => new Category(category)) : null,
    searchQuery: (state: State) => state.searchQuery,
  },
}
