import { format, parse, isValid, isAfter } from 'date-fns'
import { nl } from 'date-fns/locale'
import EstablishmentResource from '@/resources/EstablishmentResource'
import BasketItemCollection from '@/resources/BasketItemCollection'
import { Establishment } from '@/http/models/Establishment'

export default {
  basketItems: (state) => new BasketItemCollection().make(state.basketItems),
  hasBasketItems: (state) => state.basketItems.length > 0,
  hasBasketItem: (state) => (id) => !!state.basketItems.find((item) => item.product.id === id),

  hasItemsFromEstablishment: (_state, getters, rootState) => !getters.hasCart || (getters.hasEstablishment && getters.establishment?.id === rootState.establishment.establishment?.id),

  itemAmountInBasket: (state) => (id) => {
    return state.basketItems
      .filter((item) => item.product.id === id)
      .reduce((prev, current) => prev + current.amount, 0)
  },

  basketInfo: (state) => state.basketInfo,
  hasBasketInfo: (state) => state.basketInfo !== null,

  establishment: (state) => state.establishment !== null ? new Establishment(state.establishment) : null,
  hasEstablishment: (state) => state.establishment !== null,

  distributionType: (state) => state.distributionType,

  // CART getters
  hasCart: (_, getters) => getters.hasBasketItems && getters.hasEstablishment,

  couponCode: (state) => state.couponCode,
  giftCardCode: (state) => state.giftCardCode,
  restaurantTip: (state) => state.restaurantTip,

  distributionDateText: (state) => {
    let date = parse(state.distributionDate, 'dd-MM-yyyy', new Date())

    return format( date, 'd MMMM', { locale: nl })
  },
  isLaterDate: (state) => isAfter(parse(state.distributionDate, 'dd-MM-yyyy', new Date()), new Date()),
  distributionDate: (state) => state.distributionDate,
  distributionTime: (state) => state.distributionTime,
  timeslots: (state) => state.timeslots,
  timeslot: (state) => {
    const timeslotComponents = []

    let humanReadableDate = parse(state.distributionDate, 'dd-MM-yyyy', new Date())

    if (isValid(humanReadableDate)) {
      humanReadableDate = `${format( humanReadableDate, 'd MMMM', { locale: nl })}.`
    }

    if (state.establishment.state === 'allows_preorder')
      return humanReadableDate

    if (state.establishment.state === 'allows_shipping_orders') {
      // provide meta data to the component
      return new EstablishmentResource(state.establishment).meta
    }

    if (
      state.distributionDate &&
      state.distributionDate !== format(new Date(), 'dd-MM-yyyy') // if the date == today, don't show this info
    ) {
      timeslotComponents.push(humanReadableDate)
    }

    if (state.distributionTime !== 0) {
      timeslotComponents.push(state.distributionTime)
    }

    return timeslotComponents.join(' – ')
  },

  needsPayment: (_, getters) =>
    typeof getters.basketInfo.costs.requires_payment === 'boolean'
      ? getters.basketInfo.costs.requires_payment
      : true,

  paymentIssuer: (state) => state.paymentIssuer,
  paymentMethod: (state) => state.paymentMethod,

  orderUuid: (state) => state.orderUuid,

  loadedTimelots: (state) => state.loadedTimelots,

  isSyncing: (state) => state.cartSyncing,
  currentView: (state) => state.currentView,
}
