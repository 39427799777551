import { inject } from 'vue'

const SEGMENT_SYMBOL = Symbol()

// export createSegment
export { default as createSegment } from '@/plugins/globals/segment'

// Register Segment instance
export function provideSegment(app, segment) {
  app.provide(SEGMENT_SYMBOL, segment)
}

// Provide Segment instance for composition API components
export function useSegment() {
  return inject(SEGMENT_SYMBOL)
}
