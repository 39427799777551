import breakpoints from '@/config/breakpoints'
import type { ProductAttributes } from '@/http/models/ProductAttributes'

class Product {
  public id: number
  public title: string
  public description: string
  public price: number
  public images: Record<string, any> // TODO type
  public discount: any | null // TODO type
  public attributes: ProductAttributes[] = []
  public available: boolean
  public unavailableMessage: string
  public thumb: string
  public requiresAgeCheck: boolean

  constructor(product: unknown) {
    this.id = product.id
    this.title = product.title
    this.description = product.description
    this.price = product.price

    /** Surcharges */
    this.sup_surcharge = product.sup_surcharge
    this.deposit_surcharge = product.deposit_surcharge

    /** Discounts */
    if (
      'discount' in product &&
      product.discount !== null &&
      product.discount.available
    ) {
      this.discount = {
        description: product.discount.description,
        amountString: product.discount.amount_string,
        newPrice: product.discount.new_price,
        oldPrice: this.price,
      }

      // Set the new price for all the calculations
      this.price = product.discount.new_price
    } else {
      this.discount = null
    }

    /** out of stock **/
    this.available = !product.is_unavailable
    this.unavailableMessage = product.is_unavailable_message

    this.thumb = product.media.images?.cover?.small?.url

    this.requiresAgeCheck = product.requires_age_check

    this.images = Object.entries(product.media.images).reduce(
      (acc, [key, sources]) => {
        const srcSet = `${sources.small.url} ${breakpoints.sm}w,
          ${sources.medium.url} ${breakpoints.md}w,
          ${sources.large.url} ${breakpoints.lg}w,
          ${sources.medium.url} ${breakpoints.xl}w`

        const sizes = `(max-width: ${breakpoints.sm}px) ${breakpoints.sm}px,
          (max-width: ${breakpoints.md}px) ${breakpoints.md}px,
          (max-width: ${breakpoints.md}px) ${breakpoints.lg}px,
          ${breakpoints.xl}px"`

        return {
          ...acc,
          [key]: {
            sources,
            sizes,
            srcSet,
          },
        }
      },
      {}
    )
  }
}

export {
  Product,
}
