export const state = () => ({
  title: null,
  message: null,
  callback: null, // either: array or function
  closable: true,
  data: null,
  dataTestId: null,
})

export const getters = {
  title: (state) => state.title,
  message: (state) => state.message,
  closable: (state) => state.closable,
  data: (state) => state.data,
  dataTestId: (state) => state.dataTestId,
  hasError: (state) => state.message !== null,

  callbacks: (state) => state.callback,
  hasMultipleCallbacks: (state) =>
    Array.isArray(state.callback) && state.callback.length > 0,

  // modal is open if there's content
  open: (state) => !!state.title,
}

export const actions = {
  show: (
    { commit },
    {
      title,
      message,
      callback = null,
      closable = true,
      data = null,
      dataTestId = null,
    }
  ) => {
    commit('SET_TITLE', title)
    commit('SET_MESSAGE', message)
    commit('SET_CALLBACK', callback)
    commit('SET_CLOSABLE', closable)
    commit('SET_DATA', data)
    commit('SET_DATA_TEST_ID', dataTestId)
  },

  callback({ state, dispatch }, index) {
    if (
      Array.isArray(state.callback) &&
      index in state.callback &&
      typeof state.callback[index].action === 'function'
    )
      state.callback[index].action()

    dispatch('reset')
  },

  reset: ({ commit, state }) => {
    if (typeof state.callback === 'function') state.callback()

    commit('SET_TITLE', null)
    commit('SET_MESSAGE', null)
    commit('SET_CALLBACK', null)
    commit('SET_CLOSABLE', true)
    commit('SET_DATA', null)
    commit('SET_DATA_TEST_ID', null)
  },
}

export const mutations = {
  SET_TITLE: (state, title) => (state.title = title),
  SET_MESSAGE: (state, message) => (state.message = message),
  SET_CALLBACK: (state, callback) => (state.callback = callback),
  SET_CLOSABLE: (state, closable) => (state.closable = closable),
  SET_DATA: (state, data) => (state.data = data),
  SET_DATA_TEST_ID: (state, dataTestId) => (state.dataTestId = dataTestId),
}
