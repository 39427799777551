import {
  formatDistSlug,
  formatProducts,
  getAnonymousId,
  getContext
} from '@/plugins/globals/segment/lib/helpers'

export const ACCOUNT_DELETED = 'account_deleted'
export const ADDRESS_ADDED = 'address_added'
export const ADDRESS_DELETED = 'address_deleted'
export const ADDRESS_SEARCHED = 'address_searched'
export const ADDRESS_SEARCHED_MANUALLY = 'address_searched_manually'
export const CART_CHANGE_AMOUNT = 'cart_changeAmount'
export const CART_EMPTY = 'cart_empty'
export const CART_PRODUCT_ADDED = 'cart_productAdded'
export const CATEGORY_VIEWED = 'category_viewed'
export const CITY_VIEWED = 'city_viewed'
export const MARKETING_PAGE_VIEWED = 'marketing_page_viewed'
export const CHECKOUT_COMPLETED = 'checkout_completed'
export const CHECKOUT_SIGNED_UP = 'checkout_signedUp'
export const CHECKOUT_STARTED = 'checkout_started'
export const CHECKOUT_STEP_VIEWED = 'checkoutStep_viewed'
export const CHECKOUT_STEP_COMPLETED = 'checkoutStep_completed'
export const DISCOUNT_REQUESTED = 'discount_requested'
export const ESTABLISHMENT_VIEWED = 'establishment_viewed'
export const ESTABLISHMENT_INFO_VIEWED = 'establishment_info_viewed'
export const ESTABLISHMENT_LIST_VIEWED = 'establishmentList_viewed'
export const ESTABLISHMENT_SUGGESTION_SUBMITTED = 'establishmentSuggestion_submitted'
export const MARKETPLACE_VIEWED = 'marketplace_viewed'
export const NOTHEREYET_SUBSCRIBED = 'notHereYet_subscribed'
export const PAGE_TRACK = 'page_track'
export const PAYMENT_FAILED = 'payment_failed'
export const PAYMENT_STARTED = 'payment_started'
export const PRODUCT_CLICKED = 'product_clicked'
export const PRODUCT_SEARCHED = 'product_searched'
export const REVIEW_SUBMITTED = 'review_submitted'
export const REVIEWS_VIEWED = 'reviews_viewed'
export const USER_SIGNED_IN = 'user_signedIn'
export const USER_SIGNED_OUT = 'user_signedOut'
export const USER_SIGNED_UP = 'user_signedUp'
export const USER_UPDATED = 'user_updated'
export const USER_DELETED = 'user_deleted'
export const VOUCHER_CHANGED = 'discount_changed'
export const EXPERIMENT_STARTED = 'experiment_started'
export const UNSUBSCRIBE = 'unsubscribe'
export const UNSUBSCRIBE_FORM = 'unsubscribe_form'
export const EMAIL_SUBSCRIBED = 'email_subscribed'
export const SUBSCRIBE_CITY_UPDATES = 'subscribe_city_updates'
export const MODAL_TOGGLE = 'modal_toggle'

export const Handlers = {
  /**
   * @param user
   * @param api
   */
  [ACCOUNT_DELETED]: ({ user }, segmentApi) => {
    segmentApi.identify()
    segmentApi.track('Account Removed', {
      id: user.id,
      name: user.name,
      email: user.email,
    })
  },

  /**
   * @param address
   * @param api
   */
  [ADDRESS_ADDED]: ({ address }, segmentApi) => {
    segmentApi.track('Address Added', {
      street: address.street,
      city: address.city,
      postalCode: address.zipcode,
      country: 'Nederland',
    })
  },

  /**
   * @param address
   * @param api
   */
  [ADDRESS_DELETED]: ({ address }, segmentApi) => {
    segmentApi.track('Address Removed', {
      street: address.street,
      city: address.city,
      postalCode: address.zipcode,
      country: 'Nederland',
    })
  },

  /**
   * @param query
   * @param location
   * @param api
   */
  [ADDRESS_SEARCHED]: ({ query, location }, segmentApi) => {
    segmentApi.track('Address Searched', {
      q: query,
      zipcode: location.zipcode,
      street: location.street,
      city: location.city,
      lat: location.lat,
      lng: location.lng,
    })
  },

  /**
   * @param location
   * @param api
   */
  [ADDRESS_SEARCHED_MANUALLY]: (location, segmentApi) => {
    segmentApi.track('Address Searched Manually', {
      zipcode: location.zipcode,
      street: location.street,
      city: location.city,
      lat: location.lat,
      lng: location.lng,
    })
  },

  /**
   * @param item
   * @param basketItems
   * @param amount
   * @param establishment
   * @param api
   */
  [CART_CHANGE_AMOUNT]: (
    { item, amount, establishment },
    segmentApi
  ) => {
    const [formattedProduct] =
      formatProducts([item?.product], establishment) || []

    if (formattedProduct) {
      formattedProduct.amount_new = amount
      formattedProduct.amount_old = item.amount

      if (amount < item.amount) {
        segmentApi.track('Product Removed', formattedProduct)
      } else {
        segmentApi.track('Product Added', formattedProduct)
      }

      segmentApi.identify(null, {}, {
        includeCart: true
      })
    }
  },

  /**
   * @param basketItems
   * @param establishment
   * @param api
   */
  [CART_EMPTY]: ({ basketItems, establishment }, segmentApi) => {
    const eventProducts = formatProducts(
      basketItems.items.map((item) => {
        item.product.amount = item.amount

        return item.product
      }),
      establishment
    )

    segmentApi.track('Cart Emptied', {
      products: eventProducts,
    })
    segmentApi.identify(null, {
      cart: '',
    })
  },

  /**
   * @param category
   * @param api
   */
  [CATEGORY_VIEWED]: ({ category }, segmentApi) => {
    segmentApi.page('Category', {
      id: category.id,
      name: category.name,
    })
  },

  /**
   * @param product
   * @param basketItems
   * @param establishment
   * @param api
   */
  [CART_PRODUCT_ADDED]: (
    { product, establishment },
    segmentApi
  ) => {
    segmentApi.identify(null, {}, {
      includeCart: true
    })

    // We want to know if the product added through the FoodModal had an image
    // This way, we can check if having an image makes a difference when the consumer is selecting dishes
    const has_image = Object.values(product.images.cover.sources).reduce(
      (bool, { url }) => bool || !!url,
      false
    )

    // We need this regex, to extract parts of the url. This is for limiting the size of the Segment track request.
    // Less characters means we can send more products. For local, we don't need to do this.
    const iterator = product.images.cover.sources.small.url.matchAll(/library\/(\d+)\/conversions\/(.+)-sm(\..+)/g)

    const [_fullMatch, libraryId, name, extension] = [...iterator]

    const formattedProduct = {
      ...formatProducts([product], establishment)[0],
      has_image,
      img: {
        n: name,
        e: extension,
        i: libraryId
      }
    }

    segmentApi.track('Product Added', formattedProduct)
  },

  /**
   * @param city
   * @param api
   */
  [CITY_VIEWED]: ({ city }, segmentApi) => {
    segmentApi.page('City', {
      id: city.id,
      name: city.name,
    })
  },

  /**
   * @param cityCategory
   * @param api
   */
  [MARKETING_PAGE_VIEWED]: ({ marketingPage }, segmentApi) => {
    segmentApi.page('Marketing', {
      id: marketingPage.id,
      name: marketingPage.name,
      category_id: marketingPage.category?.id ?? null,
      category_name: marketingPage.category?.name ?? null,
      city_id: marketingPage.city?.id ?? null,
      city_name: marketingPage.city?.name ?? null,
    })
  },

  /**
   * @param basketInfo
   * @param paymentMethod
   * @param api
   */
  [CHECKOUT_COMPLETED]: ({ basketInfo, paymentMethod }, segmentApi) => {
    segmentApi.identify(null, {}, {
      includeCart: true
    })
    segmentApi.track('Checkout Step Completed', {
      step: 1,
      shipping_method: formatDistSlug(basketInfo.is_delivery),
      payment_method: paymentMethod?.name || null,
    })
  },

  /**
   * @param user
   * @param api
   */
  [CHECKOUT_SIGNED_UP]: ({ user }, segmentApi) => {
    segmentApi.track('Signed Up', {
      name: user.name,
      email: user.email,
      phone: user.phone,
      signup_location: 'checkout',
    })
  },

  /**
   * @param basketInfo
   * @param coupon
   * @param establishment
   * @param api
   */
  [CHECKOUT_STARTED]: ({ basketInfo, coupon, establishment }, segmentApi) => {
    segmentApi.identify(null, {}, {
      includeCart: true
    })

    segmentApi.track('Checkout Started', {
      revenue: basketInfo.costs.total,
      shipping: basketInfo.costs.shipping,
      shipping_type: basketInfo.is_delivery ? 'delivery' : 'take-away',
      discount: basketInfo.costs.total_discount,
      coupon: coupon,
      currency: 'EUR',
      products: formatProducts(basketInfo.items, establishment),
      establishment_id: establishment.id,
    })
  },

  /**
   * @param user
   * @param email
   * @param api
   */
  [DISCOUNT_REQUESTED]: ({ user, email }, segmentApi) => {
    const context = getContext()
    getAnonymousId().then((anonymousId) => {
      const userId = user.id || user.email || email || user.phone

      if (userId) {
        segmentApi.identify({
          anonymousId,
          userId,
          context,
          traits: user,
        })
      }

      segmentApi.track({
        userId,
        anonymousId,
        event: 'Discount Requested',
        properties: { type: 'Almere', email: email },
        context,
      })
    })
  },

  /**
   * @param step
   * @param basketInfo
   * @param paymentMethod
   * @param api
   */
  [CHECKOUT_STEP_VIEWED]: ({ step, basketInfo, paymentMethod }, segmentApi) => {
    const props = {}
    if (basketInfo) props.shipping_method = formatDistSlug(basketInfo.is_delivery)
    if (paymentMethod) props.payment_method = paymentMethod.name

    segmentApi.track('Checkout Step Viewed', {
      step: step,
      ...props
    })

    segmentApi.identify(null, {}, {
      includeCart: true
    })
  },

  /**
   * @param establishment
   * @param api
   */
  [ESTABLISHMENT_VIEWED]: ({ establishment }, segmentApi) => {
    segmentApi.page('Establishment', {
      brand: establishment.title,
      brand_id: establishment.id,
      brand_type: 'restaurant',
      review_rating: establishment.reviews.average,
      review_amount: establishment.reviews.amount,
    })
  },

  /**
   * @param establishments
   * @param api
   */
  [ESTABLISHMENT_LIST_VIEWED]: (establishments, segmentApi) => {
    segmentApi.track('Establishment List Viewed', {
      brands: establishments.map((item) => {
        return {
          brand_id: item.id,
          brand: item.title,
          brand_type: 'restaurant',
        }
      }),
    })
  },

  /**
   * @param user
   * @param address
   * @param content
   * @param api
   */
  [ESTABLISHMENT_SUGGESTION_SUBMITTED]: (
    { user, address, content },
    segmentApi
  ) => {
    getAnonymousId().then((anonymousId) => {
      const context = getContext()

      const userId = user.id || user.email || user.phone
      if (userId) {
        segmentApi.identify({
          anonymousId,
          userId,
          context,
          traits: user,
        })
      }

      segmentApi.track({
        event: 'Establishment Suggested',
        userId,
        anonymousId,
        properties: {
          name: content,
          address: {
            street: address.street,
            city: address.city,
            postalCode: address.zipcode,
            country: 'Nederland',
          },
        },
        context,
      })
    })
  },

  /**
   * @param api
   */
  [MARKETPLACE_VIEWED]: (segmentApi) => {
    segmentApi.page('Marketplace')
  },

  /**
   * @param email
   * @param location
   * @param api
   */
  [NOTHEREYET_SUBSCRIBED]: ({ email, location }, segmentApi) => {
    segmentApi.identify(null, {
      email: email,
      address: {
        street: location.street,
        city: location.city,
        postalCode: location.zipcode,
        country: 'Nederland',
      },
    })
    segmentApi.track('Form Submitted', {
      type: 'address_not_available',
      address: {
        street: location.street,
        city: location.city,
        postalCode: location.zipcode,
        country: 'Nederland',
      },
    })
  },

  /**
   * @param to
   * @param segmentApi
   */
  [PAGE_TRACK]: ({ to }, segmentApi) => {
    const disableAutoTrack = [
      'establishment',
      'establishment/restaurant-review',
      'slug',
      'marketplace',
    ]

    segmentApi.identify()

    const pageName = to?.name?.split('___')[0]
    if (!disableAutoTrack.includes(pageName)) {
      segmentApi.page()
    }
  },

  /**
   * @param $route
   * @param api
   */
  [PAYMENT_FAILED]: ({ $route }, segmentApi) => {
    segmentApi.track('Payment Failed', {
      order_id: $route.params.orderUuid ?? 'unknown',
    })
  },

  /**
   * @param basketInfo
   * @param establishment
   * @param coupon
   * @param api
   */
  [PAYMENT_STARTED]: ({ basketInfo, establishment, coupon }, segmentApi) => {
    const products = formatProducts(basketInfo.items, establishment)

    segmentApi.track('Payment Started', {
      revenue: basketInfo.costs.total,
      shipping: basketInfo.is_delivery ? 'delivery' : 'take-away',
      discount: basketInfo.costs.total_discount,
      coupon: coupon,
      currency: 'EUR',
      products,
    })
  },

  /**
   * @param product
   * @param establishment
   * @param categoryTitle
   * @param position
   * @param api
   */
  [PRODUCT_CLICKED]: (
    { product, establishment, categoryTitle, position },
    segmentApi
  ) => {
    segmentApi.track('Product Clicked', {
      product_id: product.id,
      sku: product.id,
      category: categoryTitle,
      name: product.title,
      description: product.description,
      brand: establishment.title,
      brand_id: establishment.id,
      image_url: product.thumb,
      has_image: !!product.thumb,
      position: position,
    })
  },

  /**
   * @param query
   * @param api
   */
  [PRODUCT_SEARCHED]: ({ query }, segmentApi) => {
    segmentApi.track('Product Searched', {
      query: query,
    })
  },

  /**
   * @param establishment
   * @param review
   * @param api
   */
  [REVIEW_SUBMITTED]: ({ establishment, review }, segmentApi) => {
    const rating =
      Math.round(
        (review.ratings.reduce(
          (total, next) => total + parseInt(next.score),
          0
        ) /
          review.ratings.length) *
          10
      ) / 10

    segmentApi.track('Review Submitted', {
      brand: establishment.title,
      brand_id: establishment.id,
      brand_type: 'restaurant',
      review_rating: rating,
      review_comment: review.content,
    })
  },

  /**
   * @param establishment
   * @param api
   */
  [REVIEWS_VIEWED]: ({ establishment }, segmentApi) => {
    segmentApi.track('Reviews Viewed', {
      brand: establishment.title,
      brand_id: establishment.id,
      brand_type: 'restaurant',
      review_rating: establishment.reviews.average,
      review_amount: establishment.reviews.amount,
    })
  },

  /**
   * @param establishment
   * @param api
   */
  [ESTABLISHMENT_INFO_VIEWED]: ({ establishment }, segmentApi) => {
    segmentApi.track('Establishment Info Viewed', {
      brand: establishment.title,
      brand_id: establishment.id,
      brand_type: 'restaurant',
    })
  },

  /**
   * @param api
   */
  [USER_SIGNED_IN]: (segmentApi) => {
    segmentApi.identify()
    segmentApi.track('Signed In')
  },

  /**
   * @param api
   */
  [USER_SIGNED_OUT]: (segmentApi) => {
    segmentApi.track('Signed Out')
    segmentApi.reset()
  },

  /**
   * @param name
   * @param email
   * @param $route
   * @param api
   */
  [USER_SIGNED_UP]: ({ name, email, $route }, segmentApi) => {
    const [page] = $route.name.split('___')

    segmentApi.identify()
    segmentApi.track('Signed Up', {
      name,
      email,
      signup_location: page,
    })
  },

  /**
   * @param api
   */
  [USER_UPDATED]: (segmentApi) => {
    segmentApi.identify()
    segmentApi.track('User Updated')
  },

  /**
   * @param api
   */
  [USER_DELETED]: (segmentApi) => {
    segmentApi.identify()
    segmentApi.track('User Deleted')
  },

  /**
   * @param newVoucher
   * @param oldVoucher
   * @param api
   */
  [VOUCHER_CHANGED]: ({ newVoucher, oldVoucher }, segmentApi) => {
    if (!newVoucher?.value && oldVoucher?.value) {
      segmentApi.track('Coupon Removed', {
        coupon_name: oldVoucher.value,
        valid: oldVoucher.is_used,
      })
    }

    if (newVoucher?.value) {
      segmentApi.track('Coupon Entered', {
        coupon_name: newVoucher?.value,
        valid: newVoucher?.is_used,
      })
    }
  },

  [EXPERIMENT_STARTED]: (
    { name: experiment_name, variant: experiment_variant },
    segmentApi
  ) => {
    segmentApi.track('Experiment Started', { experiment_name, experiment_variant })
  },

  [UNSUBSCRIBE_FORM]: ({ reason, reason_text }, segmentApi) => {
    segmentApi.track('Form Submitted', {
      type: 'unsubscribe',
      reason,
      reason_text,
    })
  },

  [EMAIL_SUBSCRIBED]: ({ email }, segmentApi) => {
    segmentApi.identify(null, {
      email,
      unsubscribed: false
    })
    segmentApi.track('Email Subscribed')
  },

  [SUBSCRIBE_CITY_UPDATES]: ({ location, email }, segmentApi) => {
    segmentApi.identify(null, {
      email
    })

    segmentApi.track('Email Subscribed', {
      type: 'city',
      city: location.city,
    })
  },

  [MODAL_TOGGLE]: ({ modal, open }, segmentApi) => {
    let event = 'Modal Opened'
    if (!open) {
      event = 'Modal Closed'
    }

    segmentApi.track(event, {
      type: modal
    })
  },
}
