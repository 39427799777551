const SET_SSR = 'SET_SSR'
const SET_SERVICE_ALERT = 'SET_SERVICE_ALERT'

const serviceAlert = {
  state: () => ({
    ssr: false,
    serviceAlert: []
  }),
  mutations: {
    [SET_SSR] (state, ssr) {
      state.ssr = ssr
    },
    [SET_SERVICE_ALERT] (state, serviceAlert) {
      state.serviceAlert = serviceAlert
    }
  },
  actions: {
    setSSR ({ commit }, ssr) {
      commit(SET_SSR, ssr)
    },
    setServiceAlert ({ commit }, serviceAlert) {
      commit(SET_SERVICE_ALERT, serviceAlert)
    }
  },
  getters: {
    ssr: state => state.ssr,
    serviceAlert: state => state.serviceAlert
  }
}

export {
  serviceAlert,
}
