import { Collection } from 'collect.js'
import ProductSetResource from '@/resources/ProductSetResource'

class ProductSetCollection extends Collection {
  make(items) {
    if (!items) return
    return super.make(
      items.map((attribute) => new ProductSetResource(attribute))
    )
  }
}

export default ProductSetCollection
