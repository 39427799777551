import UserResource from '@/resources/UserResource'
import { Address } from '@/http/models/Address'
import { TYPES as SNACK_TYPES } from '@/plugins/globals/snackbar'
import { USER_SIGNED_IN, USER_SIGNED_OUT, USER_UPDATED, USER_DELETED } from '@/plugins/globals/segment/handlers'

const SET_TOKEN = 'SET_TOKEN'
const SET_FETCHING = 'SET_FETCHING'
const RESET_USER = 'RESET_USER'
const SET_USER_COMPONENT = 'SET_USER_COMPONENT'
const SET_USER_ADDRESSES = 'SET_USER_ADDRESSES'
const SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION =
  'SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION'
const SET_GUEST_PHONE = 'SET_GUEST_PHONE'
const SET_GUEST_EMAIL = 'SET_GUEST_EMAIL'
const SET_GUEST_NAME = 'SET_GUEST_NAME'

export const getDefaultState = () => ({
  user: { phone: '', name: '', email: '' }, // contains account data only
  guest: {
    email: '',
    name: '',
    phone: '',
  },
  token: null,
  addresses: null, // contains all addresses the user has used in the past
  fetching: [],
  hasCreatedAccountFromGuestSession: false,
})

export const state = () => ({ ...getDefaultState() })

export const getters = {
  user: (state) => new UserResource(state.user),

  token: (state) => state.token,

  anyFetching: (state) => !!state.fetching.length,

  isFetching: (state) => (key) => state.fetching.includes(key),

  fetching: (state) => state.fetching,

  loggedIn: (state) => !!(state.token && state.token.access_token),

  hasCreatedAccountFromGuestSession: (state) =>
    state.hasCreatedAccountFromGuestSession,

  addresses: (state) => state.addresses?.map((address) => new Address(address)) ?? [],
}

// Internal actions
const ACTIONS = {
  FETCH_START: 'FETCH_START',
  FETCH_END: 'FETCH_END',
}

export const actions = {
  [ACTIONS.FETCH_START]: ({ commit, getters }, key) => {
    if (getters.isFetching(key)) return
    commit(SET_FETCHING, [...getters.fetching, key])
  },

  [ACTIONS.FETCH_END]: ({ commit, getters }, key) => {
    if (!getters.isFetching(key)) return
    commit(SET_FETCHING, getters.fetching.filter((k) => k !== key) || [])
  },

  setToken({ commit }, token) {
    commit(SET_TOKEN, token)
  },

  setUser({ commit }, user) {
    commit(SET_USER_COMPONENT, {
      key: 'phone',
      value: user.phone,
    })

    commit(SET_USER_COMPONENT, {
      key: 'email',
      value: user.email,
    })

    commit(SET_USER_COMPONENT, {
      key: 'name',
      value: user.name,
    })
  },

  setUserComponent: ({ commit }, { key, value }) =>
    commit(SET_USER_COMPONENT, { key, value }),

  setUserHasCreatedAccountFromGuestSession: ({ commit }, bool) => {
    commit(SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION, bool)
  },

  applyAccount({ commit }, { id, phone_number, email, name, date_of_birth }) {
    commit(SET_USER_COMPONENT, {
      key: 'id',
      value: id,
    })

    // sync user with $auth.user
    commit(SET_USER_COMPONENT, {
      key: 'phone',
      value: phone_number ?? '',
    })

    commit(SET_USER_COMPONENT, {
      key: 'email',
      value: email,
    })

    commit(SET_USER_COMPONENT, {
      key: 'name',
      value: name,
    })

    commit(SET_USER_COMPONENT, {
      key: 'birthday',
      value: date_of_birth,
    })
  },

  async refetch({ dispatch }) {
    const { data } = await this.$api.customer.account()

    dispatch('applyAccount', { id: data.id, ...data.attributes })
  },

  async login({ getters, dispatch }, { username, password }) {
    const FETCH_KEY = 'login'

    if (getters.isFetching(FETCH_KEY)) return {}

    dispatch(ACTIONS.FETCH_START, FETCH_KEY)

    const snackId = await this.$snackbar.message(
      this.$i18n.global.t('forms.messages.logging-in')
    )

    try {
      await this.$auth.loginWith('local', {
        data: {
          username,
          password,
        },
      })

      this.$snackbar.update(snackId, {
        title: this.$i18n.global.t('forms.messages.logging-in-success'),
        type: SNACK_TYPES.SUCCESS,
      })

      await dispatch('applyAccount', { ...this.$auth.user })

      this.$segment.handle(USER_SIGNED_IN)

      return { success: true }
    } catch (e) {
      this.$snackbar.update(snackId, {
        title: this.$i18n.global.t('forms.messages.logging-in-error'),
        type: SNACK_TYPES.ERROR,
      })

      return {
        success: false,
        code: e.response?.data?.code,
        message: e.response?.data?.message,
      }
    } finally {
      dispatch(ACTIONS.FETCH_END, FETCH_KEY)
    }
  },

  async logout({ commit, getters, dispatch, rootGetters }) {
    const FETCH_KEY = 'logout'

    if (getters.isFetching(FETCH_KEY)) return
    dispatch(ACTIONS.FETCH_START, FETCH_KEY)

    const snackId = await this.$snackbar.message(
      this.$i18n.global.t('forms.messages.logging-out')
    )

    try {
      await this.$auth.logout()

      this.$snackbar.update(snackId, {
        title: this.$i18n.global.t('forms.messages.logging-out-success'),
        type: SNACK_TYPES.SUCCESS,
      })

      this.$segment.handle(USER_SIGNED_OUT)

      commit(RESET_USER)

      // Reset the previous location and revert to local storage
      dispatch(
        'session/storeCurrentLocation',
        rootGetters['session/temporaryLocation'],
        { root: true }
      )

      dispatch(ACTIONS.FETCH_END, FETCH_KEY)
      return true
    } catch (e) {
      this.$snackbar.update(snackId, {
        title: this.$i18n.global.t('forms.messages.logging-out-error'),
        type: SNACK_TYPES.ERROR,
      })

      dispatch(ACTIONS.FETCH_END, FETCH_KEY)
      return false
    }
  },

  async storeAddresses({ commit }, addresses) {
    commit(SET_USER_ADDRESSES, addresses)
  },

  storeGuestName({ commit, }, name) {
    commit(SET_GUEST_NAME, name)
  },

  storeGuestPhone({ commit, }, phone) {
    commit(SET_GUEST_PHONE, phone)
  },

  storeGuestEmail({ commit, }, email) {
    commit(SET_GUEST_EMAIL, email)
  },

  async updateAccount({ dispatch }, properties) {
    await this.$api.customer.updateAccount(properties)
    await this.$auth.fetchUser()

    const { data } = await this.$api.customer.account()

    await dispatch('applyAccount', { id: data.id, ...data.attributes })

    this.$segment.handle(USER_UPDATED)
  },

  async delete({ commit }) {
    this.$segment.handle(USER_DELETED)

    await this.$api.customer.deleteAccount()

    commit(RESET_USER)
  }
}

export const mutations = {
  [SET_FETCHING]: (state, array) => (state.fetching = array),

  [SET_TOKEN]: (state, token) => (state.token = token),

  [RESET_USER]: (state) => {
    Object.entries(getDefaultState()).forEach(([key, value]) => {
      state[key] = value
    })
  },

  [SET_USER_COMPONENT]: (state, { key, value }) => {
    if (!state.user) state.user = {}
    state.user = { ...state.user, [key]: value } // for reactivity
  },

  [SET_USER_ADDRESSES]: (state, addresses) => {
    state.addresses = addresses
  },

  [SET_USER_HAS_CREATED_ACCOUNT_FROM_GUEST_SESSION]: (state, bool) => {
    state.hasCreatedAccountFromGuestSession = bool
  },

  [SET_GUEST_PHONE]: (state, phone) => (state.guest.phone = phone),

  [SET_GUEST_EMAIL]: (state, email) => (state.guest.email = email),

  [SET_GUEST_NAME]: (state, name) => (state.guest.name = name),
}
