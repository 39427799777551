import { getDefaultState } from './state'
import BasketItemResource from '@/resources/BasketItemResource'

export const ADD_TO_BASKET_ITEMS = 'ADD_TO_BASKET_ITEMS'
export const CHANGE_PRODUCTSET_OF_BASKET_ITEM = 'CHANGE_PRODUCTSET_OF_BASKET_ITEM'
export const CHANGE_AMOUNT_OF_BASKET_ITEM = 'CHANGE_AMOUNT_OF_BASKET_ITEM'
export const RESET_CART = 'RESET_CART'
export const RESET_BASKET_INFO = 'RESET_BASKET_INFO'
export const RESET_BASKET_ITEMS = 'RESET_BASKET_ITEMS'
export const SET_ESTABLISHMENT = 'SET_ESTABLISHMENT'
export const SET_ESTABLISHMENT_DISTRIBUTION = 'SET_ESTABLISHMENT_DISTRIBUTION'
export const SET_BASKET_INFO = 'SET_BASKET_INFO'
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const SET_GIFTCARD_CODE = 'SET_GIFTCARD_CODE'
export const SET_RESTAURANT_TIP = 'SET_RESTAURANT_TIP'
export const SET_DISTRIBUTION_TYPE = 'SET_DISTRIBUTION_TYPE'
export const SET_DISTRIBUTION_DATE = 'SET_DISTRIBUTION_DATE'
export const SET_DISTRIBUTION_TIME = 'SET_DISTRIBUTION_TIME'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_PAYMENT_ISSUER = 'SET_PAYMENT_ISSUER'
export const SET_ORDER_UUID = 'SET_ORDER_UUID'
export const SET_TIME_SLOTS = 'SET_TIME_SLOTS'
export const SET_LOADED_TIME_SLOTS = 'SET_LOADED_TIME_SLOTS'
export const SET_CART_SYNCING = 'SET_CART_SYNCING'
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW'
export const SET_INSTRUCTIONS_OF_BASKET_ITEM = 'SET_INSTRUCTIONS_OF_BASKET_ITEM'

export default {
  [ADD_TO_BASKET_ITEMS]: (
    state,
    { product, productSetId, selectedAttributes, amount, basketItems, }
  ) => {
    const basketItemResource = new BasketItemResource({
      product,
      productSetId,
      selectedAttributes,
      amount,
    })

    const index = basketItems.items.findIndex(basketItem => basketItem.hash() === basketItemResource.hash())

    if (index === -1) {
      // in this case: product doesn't exist yet
      state.basketItems.push({
        product: basketItemResource.product,
        selectedAttributes: Array.from(selectedAttributes), // Make key, value array since Maps can't be persisted
        amount,
      })
    } else {
      // product exists, increment it with $amount
      state.basketItems[index].amount += basketItemResource.amount
    }
  },

  [SET_INSTRUCTIONS_OF_BASKET_ITEM]: (state, { hash, instructions, basketItems }) => {
    const index = basketItems.items.findIndex(item => item.hash() === hash)
    state.basketItems[index].product.instructions = instructions
  },

  [CHANGE_PRODUCTSET_OF_BASKET_ITEM]: (state, { hash, productSetId, basketItems, }) => {
    const index = basketItems.items.findIndex(item => item.hash() === hash)
    state.basketItems[index].product.product_set_id = productSetId
  },

  [CHANGE_AMOUNT_OF_BASKET_ITEM]: (state, { hash, amount, basketItems, }) => {
    const index = basketItems.items.findIndex(item => item.hash() === hash)

    // If the amount == 0, assume that we need to remove the basket item
    if (amount === 0) {
      state.basketItems.splice(index, 1)
    } else {
      state.basketItems[index].amount = amount
    }
  },

  [RESET_CART]: (state) => {
    const defaults = getDefaultState()

    state.orderUuid = null
    state.establishment = defaults.establishment
    state.distributionType = defaults.distributionType

    state.basketItems = [] // defaults.basketItems
    state.basketInfo = defaults.basketInfo
    state.restaurantTip = defaults.restaurantTip

    state.distributionDate = defaults.distributionDate
    state.distributionTime = defaults.distributionTime

    state.loadedTimeslots = defaults.loadedTimelots

    // vouchers
    state.couponCode = null
    state.giftCardCode = null
  },

  [RESET_BASKET_INFO]: (state) =>
    (state.basketInfo = getDefaultState().basketInfo),

  [RESET_BASKET_ITEMS]: (state) =>
    (state.basketItems = getDefaultState().basketItems),

  [SET_ESTABLISHMENT]: (state, establishment) => (state.establishment = establishment),

  [SET_ESTABLISHMENT_DISTRIBUTION]: (state, establishmentDistribution) => {
    if (state.establishment !== null) {
      state.establishment = {
        ...state.establishment,
        ...establishmentDistribution,
      }
    }
  },

  [SET_BASKET_INFO]: (state, basketInfo) => (state.basketInfo = basketInfo),

  [SET_TIME_SLOTS]: (state, timeslots) => (state.timeslots = timeslots),

  [SET_COUPON_CODE]: (state, couponCode) => (state.couponCode = couponCode),
  [SET_GIFTCARD_CODE]: (state, couponCode) => (state.giftCardCode = couponCode),

  [SET_RESTAURANT_TIP]: (state, restaurantTip) =>
    (state.restaurantTip = restaurantTip),

  [SET_DISTRIBUTION_TYPE]: (state, distributionType) =>
    (state.distributionType = Number(distributionType)),

  [SET_DISTRIBUTION_DATE]: (state, distributionDate) =>
    (state.distributionDate = distributionDate),

  [SET_DISTRIBUTION_TIME]: (state, distributionTime) =>
    (state.distributionTime = distributionTime),

  [SET_PAYMENT_METHOD]: (state, paymentMethod) =>
    (state.paymentMethod = paymentMethod),

  [SET_PAYMENT_ISSUER]: (state, paymentIssuer) =>
    (state.paymentIssuer = paymentIssuer),

  [SET_ORDER_UUID]: (state, uuid) => state.orderUuid = uuid,

  [SET_LOADED_TIME_SLOTS]: (state, loadedTimelots) => (state.loadedTimelots = loadedTimelots),

  [SET_CART_SYNCING]: (state, bool) => (state.cartSyncing = bool),

  [SET_CURRENT_VIEW]: (state, view) => (state.currentView = view),
}
