import * as Sentry from '@sentry/vue'
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const Taptic = (type) => {
  try {
    if (typeof Haptics !== 'undefined' && navigator.vibrate) {
      switch (type) {
        case 'light':
        case 'weak':
        case 'selection':
        case 'gesture':
          Haptics.impact({ style: ImpactStyle.Light })
          break
        case 'error':
        case 'success':
        case 'warning':
        case 'medium':
        case 'burst':
          Haptics.impact({ style: ImpactStyle.Medium })
          break
        case 'heavy':
          Haptics.impact({ style: ImpactStyle.Heavy })
          break
      }
    }
  } catch (e) {
    sentry?.captureException(e)
  }
}

export default {
  mounted(el, binding) {
    el.addEventListener('click', function () {
      Taptic(binding.value)
    })
  },
}
