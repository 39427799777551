import { disableBodyScroll as lockBody, enableBodyScroll as unlockBody } from 'body-scroll-lock'

const isIosSafari = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iP(ad|od|hone)/i)
  const webkit = !!ua.match(/WebKit/i)
  return iOS && webkit
}

const isIosChrome = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iP(ad|od|hone)/i);
  const chrome = !!ua.match(/CriOS/i);
  return iOS && chrome
}

const preserveScrollBarGap = () => {
  // get the document's viewport width
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )

  const firstChild = document.querySelector('body > :first-child')

  const gap = vw - firstChild?.clientWidth

  if (gap > 0) {
    firstChild.style.paddingRight = `${gap}px`
  }
}

const resetScrollBarGap = () => {
  const firstChild = document.querySelector('body > :first-child')
  if (firstChild) firstChild.style.paddingRight = '0px'
}

const disableBodyScroll = (el) => {
  // run this before adding overflow: hidden
  preserveScrollBarGap()

  if (isIosSafari() || isIosChrome()) {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}`
    document.body.style.overflow = 'hidden'
  } else {
    lockBody(el)
  }

  el.style['overscroll-behavior-y'] = 'contain'
}

const enableBodyScroll = (el) => {
  if (isIosSafari() || isIosChrome()) {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    document.body.style.overflow = ''
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY))
  } else {
    unlockBody(el)
  }

  el.style['overscroll-behavior-y'] = 'auto'
  resetScrollBarGap()
}

export default {
  mounted(el, binding) {
    if (binding.value) {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
      disableBodyScroll(el)
    }
  },

  updated(el, binding) {
    if (binding.oldValue === binding.value) return

    if (binding.value) {
      disableBodyScroll(el)
    } else {
      enableBodyScroll(el)
    }
  },

  beforeUnmount(el) {
    enableBodyScroll(el)
  },
}
