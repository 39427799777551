export const TYPES = {
  MESSAGE: 'MESSAGE',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
}

/*
 * Snack
 * For the options, check Snack.vue's props
 */

export default ({ store }, inject) => {
  const dispatch = (options) => store.dispatch('snackbar/add', options)

  const format = (options) => {
    if (typeof options === 'string') return { title: options }
    return options
  }

  const message = (options) =>
    dispatch({
      ...format(options),
      type: TYPES.MESSAGE,
    })

  const success = (options) =>
    dispatch({
      ...format(options),
      type: TYPES.SUCCESS,
    })

  const warning = (options) =>
    dispatch({
      ...format(options),
      type: TYPES.WARNING,
    })

  const error = (options) =>
    dispatch({
      ...format(options),
      type: TYPES.ERROR,
    })

  const update = (uuid, options) =>
    store.dispatch('snackbar/update', { uuid, ...options })

  inject('snackbar', {
    message,
    success,
    warning,
    error,
    update,
  })
}
