export type State = {
  ssr: boolean
  page: SlugType | null
}

export enum SlugType {
  Cities = 'cities',
  Categories= 'categories',
  MarketingPages = 'marketing_pages'
}

const SET_PAGE = 'SET_PAGE'
const SET_SSR = 'SET_SSR'

export const slug = {
  state: () => ({
    ssr: false,
    page: null,
  }),
  mutations: {
    [SET_PAGE]: (state: State, page: SlugType) => (state.page = page),
    [SET_SSR]: (state: State, ssr: boolean) => (state.ssr = ssr),
  },
  actions: {
    setPage({ commit }, page: SlugType) {
      commit(SET_PAGE, page)
    },
    setSSR({ commit }, ssr: boolean) {
      commit(SET_SSR, ssr)
    },
  },
}
