class Location {
  id: number | null
  street: string
  houseNumber: string
  zipcode: string
  city: string
  country: string
  coordinates: { lng: number; lat: number }
  title: string | null

  constructor(location: any) {
    this.id = location.id ?? null
    this.street = location.street
    this.houseNumber = location.houseNumber ?? null
    this.zipcode = location.zipcode
    this.city = location.city
    this.country = location.country ?? 'nl'
    this.title = location.title ?? null

    const coordinates = location.coordinates ?? location

    this.coordinates = {
      lng: coordinates.lng,
      lat: coordinates.lat,
    }
  }
}

export { Location }
