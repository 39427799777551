// API factory for backend calls related to Orders
export default ($axios) => ({
  validate: async (data) => {
    const {
      data: { data: responseData, },
    } = await $axios({
      method: 'POST',
      url: 'basket',
      data,
      headers: { 'content-type': 'application/json', },
    })

    return responseData
  },

  changeDistributionType: async (data)=> {

    const {
      data: { data: responseData, },
    } = await $axios({
      method: 'POST',
      url: 'basket/change-distribution',
      data: data,
      headers: { 'content-type': 'application/json', },
    })

    return responseData

  },

  verifyDiscountCode: async (id, code) => {
    const { data, } = await $axios({
      method: 'POST',
      url: 'basket/verify-discount-code',
      data: { establishment_id: id, code },
      headers: { 'content-type': 'application/json' },
    })

    return data.data
  },
})
