export default function ({
  store,
  app: { $axios, i18n, $sentry },
  appVersion,
}) {
  $axios.interceptors.request.use((req) => {
    req.headers['Access-Control-Allow-Origin'] = '*'
    req.headers['Accept'] = 'application/json'
    req.headers['Accept-Language'] = i18n.global.locale.value
    req.headers['X-Country'] = store.state.session.country
    req.headers['App-Version'] = appVersion

    if (appVersion !== undefined) {
      req.headers['App-Version'] = appVersion
    }

    return req
  })

  $axios.interceptors.response.use((res) => {
    // After we fetched a valid response, reset the last known API error
    if (
      store.getters['modal/hasError'] && // if we have an error
      store.getters['modal/data'] !== null &&
      'message' in store.getters['modal/data'] && // and the previous error ...
      store.getters['modal/data'].message.includes('Network Error') // ... contains "Network Error"
    ) {
      // then: reset the error
      store.dispatch('modal/reset')
    }

    return res
  })

  const onError = (error) => {
    if (import.meta.env.DEV) {
      console.error(error)
    }

    if (error.config.reject) {
      return Promise.reject(error)
    }

    $sentry?.captureException(error)

    // if we do not want any error modals to show, easy out
    // default value for showErrorModal is 'undefined'
    if (error.config.showErrorModal === false) {
      return Promise.reject(error)
    }

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx)

      if (
        error.response.status === 400 &&
        error.response.data?.code === 'INVALID_TIMESLOT'
      ) {
        const responseData = error.response.data
        // Show the message from the server (timeslot not available anymore)
        // and in the callback, make sure we reload timeslots and show the checkout details modal
        store.dispatch('modal/show', {
          dataTestId: 'invalid-timeslot-modal',
          message: responseData.message,
        })
        return Promise.reject(error)
      }

      // if we have non-existing products in hour baskets
      if (
        error.response.status === 400 &&
        // data.data could be undefined for legacy server responses
        error.response.data.data?.code === 'UNKNOWN_PRODUCTS'
      ) {
        return Promise.reject(error)
      }

      // Don't show modals for 404's when page is loaded via SSR
      if (error.response.status === 404 && import.meta.env.SSR) {
        return Promise.reject(error)
      }

      const modal = {
        closable: true,
        data: error,
      }

      // Set the general message
      let message = i18n.global.t('errors.error-from-api')

      if (error.request.method === 'POST' && error.response.status === 422) {
        // Likely a validation error by Laravel
        // craft a message from the api response
        modal.title = error.response.data?.message
        message = ''

        const errors = error.response.data?.errors
        if (errors.length > 0) {
          errors.forEach((errorMessage) => {
            message = `${message} ${errorMessage}`
          })
        }
      } else if (
        error.response.status === 400 &&
        error.response.data?.code === 'UPGRADE_REQUIRED'
      ) {
        // We need to show the user that the app needs to be updated
        message = i18n.global.t('errors.update-required')
        modal.closable = false
      }

      modal.message = message

      store.dispatch('modal/show', modal)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      store.dispatch('modal/show', {
        message: i18n.global.t('errors.connection-lost'),
        closable: false,
        data: error,
      })
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message)
    }

    return Promise.reject(error)
  }

  $axios.interceptors.request.use((req) => req, onError)
  $axios.interceptors.response.use((res) => res, onError)
}
