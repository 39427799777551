
export const namespaced = true

export const state = () => ({
  cities: [],
})

export const getters = {
  cities: (state) => state.cities,
}

export const actions = {
  async fetchCities({ commit }) {
    const cities = await this.$api.city.get()
    commit('SET_CITIES', { cities })
  },
}

export const mutations = {
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  }
}
