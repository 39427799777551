export const VIEWS = {
  LOGIN_FORM: 'LOGIN_FORM',
  DISTRIBUTION_DETAILS: 'DISTRIBUTION_DETAILS',
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
}

export const getDefaultState = () =>
  Object.freeze({
    establishment: null,

    distributionType: null, // distribution type of the cart

    basketItems: [], // local cart
    basketInfo: null, // server cart

    couponCode: null,
    giftCardCode: null,
    restaurantTip: 0,

    timeslots: [],
    distributionDate: null,
    distributionTime: null,

    paymentIssuer: null,
    paymentMethod: null,

    orderUuid: null,

    loadedTimelots: false,

    cartSyncing: false,

    currentView: VIEWS.DISTRIBUTION_DETAILS,
  })

export default () => ({ ...getDefaultState() })
