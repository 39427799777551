import { Collection } from 'collect.js'
import BasketItemResource from '@/resources/BasketItemResource'

class BasketItemCollection extends Collection {
  make(items) {
    if (!items) return

    return super.make(
      items.map((basketItem) => new BasketItemResource(basketItem))
    )
  }
}

export default BasketItemCollection
