import { useAxios } from '@/composables/useAxios'
import { DEFAULT_OPTIONS } from '../config'

// API factory for backend calls related to Location
const api = ($axios) => ({
  getAddress: async (options = DEFAULT_OPTIONS) => {
    const { data } = await $axios.$get(
      'location/address',
      options
    )
    return data
  },

  getAddressByZipCode: async (zipCode, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios.$get(
      'location/address',
      {
        ...options,
        params: {
          zipcode: zipCode,
        },
      }
    )

    return data
  },
})

export const useLocationApi = () => {
  const axios = useAxios()
  return api(axios)
}

export default api
