export default class CustomerResource {
  constructor(data) {
    if (!data) return
    if ('raw' in data) data = data.raw

    this.email = data.email
    this.name = data.name
    this.phone = data.phone
  }
}
