// API factory for backend calls related to Order
import { DEFAULT_OPTIONS } from '../config'

export default ($axios) => ({
  restore: async (id, options = DEFAULT_OPTIONS) => {
    const {
      data: { data },
    } = await $axios({
      method: 'POST',
      url: `orders/${id}/restore`,
      ...options,
    })

    return data
  },

  reorder: async (id, options = DEFAULT_OPTIONS) => {
    const {
      data: { data },
    } = await $axios({
      method: 'POST',
      url: `orders/${id}/reorder`,
      ...options,
    })

    return data
  },

  findByUuid: async (id, options = DEFAULT_OPTIONS) => {
    const {
      data: { data },
    } = await $axios({
      method: 'GET',
      url: `orders/${id}`,
      ...options,
    })

    return data
  },

  create: async (orderData, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      method: 'POST',
      url: 'orders',
      data: orderData,
      headers: { 'content-type': 'application/json' },
      ...options,
    })

    return data.data
  },

  stripeIntegration: async (order) => {
    const { data } = await $axios({
      method: 'POST',
      url: `orders/${order}/payment/integrations/stripe`,
      headers: { 'content-type': 'application/json' },
    })

    return data
  },
})
