export type State = {
  category: null
  establishments: []
  categories: []
  ssr: boolean
}

export const category = {
  state: ({
    category: null,
    establishments: [],
    categories: [],
    ssr: false,
  }),
  mutations: {
    SET_CATEGORY: (state, category) => (state.category = category),
    SET_ESTABLISHMENTS: (state, establishments) => (state.establishments = establishments),
    SET_CATEGORIES: (state, categories) => (state.categories = categories),
    SET_SSR: (state, ssr) => (state.ssr = ssr),
  },
  actions: {
    setCategory({ commit }, category) {
      commit('SET_CATEGORY', category)
    },
    setEstablishments({ commit }, establishments) {
      commit('SET_ESTABLISHMENTS', establishments)
    },
    setCategories({ commit }, categories) {
      commit('SET_CATEGORIES', categories)
    },
    setSSR({ commit }, ssr) {
      commit('SET_SSR', ssr)
    },
  },
  getters: {
    category: state => state.category,
    categories: state => state.categories,
    establishments: state => state.establishments,
  },
}
