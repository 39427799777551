import { BasketItemProductResource } from '@/resources/cart/BasketItemProductResource'

class BasketItemResource {
  constructor({ product, productSetId, selectedAttributes, amount }) {
    this.product = BasketItemProductResource.fromProductResource(product, productSetId, selectedAttributes)
    this.amount = amount

    if (!(selectedAttributes instanceof Map)) {
      // When selectedAttributes is NOT a map, construct a new Map() from key, value pairs
      // since this is persisted in local storage (Map() can't be saved)
      this.selectedAttributes = new Map(selectedAttributes)
    } else {
      this.selectedAttributes = selectedAttributes
    }
  }

  /**
   * Calculate the price of a basket item
   * @returns number (Price)
   */
  price() {
    // TODO: fix this function to new format
    return (
      this.product.calculatePriceWithAttributes(this.selectedAttributes) *
      this.amount
    )
  }

  hash() {
    return this.product.hash
  }
}

export default BasketItemResource
