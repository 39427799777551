import ProductCollection from './ProductCollection'

class ProductSetResource {
  constructor(productSet) {
    if (!productSet) return
    if ('raw' in productSet) productSet = productSet.raw

    this.id = productSet.id
    this.title = productSet.attributes.title
    this.description = productSet.attributes.description

    /** Discounts */
    if (
      'discount' in productSet.attributes &&
      productSet.attributes.discount !== null &&
      productSet.attributes.discount.available
    ) {
      this.discount = {
        description: productSet.attributes.discount.description,
        amountString: productSet.attributes.discount.amount_string,
      }
    } else {
      this.discount = null
    }

    /** Availability */
    this.available_from = productSet.attributes.available_from
    this.available_until = productSet.attributes.available_until

    /** All products */
    this.products = new ProductCollection().make(
      productSet.relationships.products
    )

    this.raw = productSet
  }
}

export default ProductSetResource
