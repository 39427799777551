import type { RouteRecordRaw } from 'vue-router'

const AppstoreRedirect = () => import('@/pages/appstore-redirect.vue')
const Cookie = () => import('@/pages/cookie.vue')
const CustomerSupport = () => import('@/pages/customer-support.vue')
const Privacy = () => import('@/pages/privacy.vue')
const Terms = () => import('@/pages/terms.vue')
const Home = () => import('@/pages/home/home.vue')
const AllCities = () => import('@/pages/all-cities.vue')
const AllCategories = () => import('@/pages/all-categories.vue')
const Checkout = () => import('@/pages/checkout/checkout.vue')
const Thanks = () => import('@/pages/thanks/thanks.vue')
const Marketplace = () => import('@/pages/marketplace/Marketplace.vue')
const Slug = () => import('@/pages/Slug.vue')
const Establishment = () => import('@/pages/establishment/Establishment.vue')
const NotFound = () => import('@/pages/404.vue')
const Unsubscribe = () => import('@/pages/unsubscribe.vue')
const Subscribe = () => import('@/pages/subscribe.vue')
const Account = () => import('@/pages/account/Account.vue')
const AccountLogin = () => import('@/pages/account/Login.vue')
const AccountRecover = () => import('@/pages/account/Recover.vue')
const AccountRegister = () => import('@/pages/account/Register.vue')
const AccountVerified = () => import('@/pages/account/Verified.vue')
const AccountOrders = () => import('@/pages/account/orders/orders.vue')
const AccountPersonalDetails = () => import('@/pages/account/personal-details/personal-details.vue')
const AccountSettings = () => import('@/pages/account/settings/settings.vue')
const AccountVouchers = () => import('@/pages/account/vouchers/vouchers.vue')

type CustomRouteRecord = Omit<RouteRecordRaw, 'path' | 'children'> & { path: Record<string, string> | string, children?: CustomRouteRecord[] }

const routes: CustomRouteRecord[] = [
  {
    path: 'privacy-statement',
    component: Privacy,
    name: 'privacy',
  },
  {
    path: 'terms-and-conditions',
    component: Terms,
    name: 'terms',
  },
  {
    path: 'appstore-redirect',
    component: AppstoreRedirect,
    name: 'AppstoreRedirect',
  },
  {
    path: 'cookie-declaration',
    component: Cookie,
    name: 'cookie',
  },
  {
    path: 'customer-support',
    component: CustomerSupport,
    name: 'customer-support',
  },
  {
    path: 'unsubscribe',
    component: Unsubscribe,
    name: 'unsubscribe',
  },
  {
    path: 'subscribe',
    component: Subscribe,
    name: 'subscribe',
  },
  {
    path: 'login',
    component: AccountLogin,
    name: 'login',
  },
  {
    path: 'register',
    component: AccountRegister,
    name: 'register',
  },
  {
    path: 'account/recover',
    component: AccountRecover,
    name: 'recover',
  },
  {
    path: 'account/verified',
    component: AccountVerified,
    name: 'verified',
  },
  {
    path: 'account',
    component: Account,
    name: 'customer-account',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'account/vouchers',
        component: AccountVouchers,
        name: 'customer-account-vouchers',
      },
      {
        path: 'account/orders',
        component: AccountOrders,
        name: 'customer-account-orders',
      },
      {
        path: 'account/personal-details',
        component: AccountPersonalDetails,
        name: 'customer-account-personal-details',
      },
      {
        path: 'account/settings',
        component: AccountSettings,
        name: 'customer-account-settings',
      },
    ],
  },
  {
    path: '',
    component: Home,
    name: 'home',
  },
  {
    path: 'not-found',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: {
      en: 'all-categories',
      nl: 'alle-categorieen',
    },
    component: AllCategories,
    name: 'all-categories',
  },
  {
    path: {
      nl: 'alle-steden',
      en: 'all-cities',
    },
    component: AllCities,
    name: 'all-cities',
  },
  {
    path: 'checkout',
    component: Checkout,
    name: 'checkout',
  },
  {
    path: 'thanks/:orderUuid',
    component: Thanks,
    name: 'thanks',
  },
  {
    path: {
      nl: 'marktplaats',
      en: 'marketplace',
    },
    component: Marketplace,
    name: 'marketplace',
  },
  {
    path: ':country?/:city/restaurants/:slug/:onLocationId?/:direct?',
    component: Establishment,
    name: 'establishment',
  },
  {
    path: ':country?/:city/restaurants/:slug/review/:review',
    component: Establishment,
    name: 'establishment-add-review',
  },
  {
    path: ':slug([^/]+)',
    component: Slug,
    name: 'slug',
  },
  {
    path: ':pathMatch(.*)*',
    component: NotFound,
    name: 'not-found',
  },
]

function mapRoutesWithLocale(route: CustomRouteRecord, locale: string, currentLocale: string): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = []
  let children: RouteRecordRaw[] = []

  if (currentLocale === 'be') {
    currentLocale = 'nl'
  }

  if (route.children !== undefined) {
    children = [ ...children, ...route.children.flatMap((child) => mapRoutesWithLocale(child, locale, currentLocale)) ]
  }

  if (typeof route.path === 'string') {
    routes.push({
      name: `${route.name as string}___${locale}`,
      component: route.component,
      path: locale === currentLocale ? `/${route.path}` : `/${locale}/${route.path}`,
      meta: route.meta,
      children,
    })
  } else {
    routes.push(...mapRoutesWithLocale({ ...route, path: route.path[locale] }, locale, currentLocale))
  }

  return routes
}

export {
  mapRoutesWithLocale,
  routes,
}
