import { Handlers } from "@/plugins/globals/segment/handlers";

export default class SegmentHandler {

  /**
   * @param segmentApi
   * @param sentry
   */
  constructor(segmentApi, sentry) {
    this.segmentApi = segmentApi
    this.sentry = sentry
  }

  /**
   * @param handlerName
   * @param props
   *
   * @returns {*}
   */
  handle(handlerName, props) {
    if (import.meta.env.SSR || window.sessionStorage.getItem('notrack') === "true") {
      return true
    }

    try {
      if (props === undefined) {
        return Handlers[handlerName](this.segmentApi)
      }

      return Handlers[handlerName](props, this.segmentApi)
    } catch (e) {
      if (import.meta.env.DEV) console.log(e)
      this.sentry?.captureException(e)
    }
  }
}
