import createPageTracker from './lib/pageTracker.js'
import SegmentHandler from "./core/segmentHandler";
import { AnalyticsBrowser } from '@segment/analytics-next'
import SegmentApi from "@/plugins/globals/segment/core/segmentApi";

const segment = AnalyticsBrowser.load({
  writeKey: import.meta.env.VITE_SEGMENT_KEY,
})

export default async ({ store, router, sentry }) => {

  const segmentApi = new SegmentApi(segment, store)
  const segmentHandler = new SegmentHandler(segmentApi, sentry)

  createPageTracker({ router, segmentHandler })

  return segmentHandler
}


export {segment}