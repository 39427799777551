import AttributeCollection from './AttributeCollection'
import EstablishmentResource from './EstablishmentResource'
import AttributeTypes from '@/config/AttributeTypes'
import breakpoints from '@/config/breakpoints'

class ProductResource {
  constructor(product) {
    if (!product) return
    if ('raw' in product) product = product.raw

    this.id = product.id
    this.title = product.attributes.title
    this.description = product.attributes.description
    this.price = product.attributes.price

    /** Surcharges */
    this.sup_surcharge = product.attributes.sup_surcharge
    this.deposit_surcharge = product.attributes.deposit_surcharge

    /** Discounts */
    if (
      'discount' in product.attributes &&
      product.attributes.discount !== null &&
      product.attributes.discount.available
    ) {
      this.discount = {
        description: product.attributes.discount.description,
        amountString: product.attributes.discount.amount_string,
        newPrice: product.attributes.discount.new_price,
        oldPrice: this.price,
      }

      // Set the new price for all the calculations
      this.price = product.attributes.discount.new_price
    } else {
      this.discount = null
    }

    /** out of stock **/
    this.available = !product.attributes.is_unavailable
    this.unavailableMessage = product.attributes.is_unavailable_message

    this.attributes = new AttributeCollection().make(
      product?.relationships?.product_attributes || []
    )

    if ('relationships' in product) {
      if ('media' in product.relationships) {
        this.thumb = product.relationships.media.images?.cover?.small?.url

        this.images = Object.entries(product.relationships.media.images).reduce(
          (acc, [key, sources]) => {
            const srcSet = `${sources.small.url} ${breakpoints.sm}w,
              ${sources.medium.url} ${breakpoints.md}w,
              ${sources.large.url} ${breakpoints.lg}w,
              ${sources.medium.url} ${breakpoints.xl}w`

            const sizes = `(max-width: ${breakpoints.sm}px) ${breakpoints.sm}px,
              (max-width: ${breakpoints.md}px) ${breakpoints.md}px,
              (max-width: ${breakpoints.md}px) ${breakpoints.lg}px,
              ${breakpoints.xl}px"`

            return {
              ...acc,
              [key]: {
                sources,
                sizes,
                srcSet,
              },
            }
          },
          {}
        )
      }

      if ('establishment' in product.relationships) {
        this.establishment = new EstablishmentResource(
          product.relationships.establishment
        )
      }
    }

    this.raw = product
  }

  /**
   * Calculate the price of an item, based on
   * the product and the passed selected attributes
   * @returns the price of the item
   */
  calculatePriceWithAttributes(selectedAttributes) {
    let basicPrice = this.price

    // Loop over all attributes to see which products are selected
    for (const [attributeId, subProductIds] of selectedAttributes.entries()) {

      // compute attribute info for the attribute we are considering
      const attribute = this.attributes
        .filter((item) => String(item.id) === String(attributeId))
        .first()

      // for all the products in this attribute:
      // see if a product has been selected; if so: add the price
      // to the basic price that we're returning
      attribute.subProducts
        .filter((item) => {
          if (attribute.type === AttributeTypes.DropDown || attribute.type === AttributeTypes.OptionalDropdown) {
            // NB: in the case subProductIds is just ONE id
            return String(item.id) === String(subProductIds)
          } else if (attribute.type === AttributeTypes.CheckBox) {
            return subProductIds[String(item.id)]
          }
          return false
        })
        .all()
        .forEach((product) => {
          // we found the product(s) of which we have to add the price!
          basicPrice += product.price
        })
    }

    return basicPrice
  }

  /**
   * For the cart, we need an empty array to hold selected attributes
   * for a specific product. @returns empty attributes array for a product
   */
  prepareEmptyAttributesArray() {
    const emptyAttributesArray = new Map()

    this.attributes.all().forEach((attribute) => {
      if (attribute.type === AttributeTypes.DropDown) {
        emptyAttributesArray.set(
          String(attribute.id),
          String(attribute.subProducts.first().id)
        )
      } else if (attribute.type === AttributeTypes.OptionalDropdown) {
        emptyAttributesArray.set(
          String(attribute.id),
          String('-1')
        )
      } else if (attribute.type === AttributeTypes.CheckBox) {
        let attributeValue = {}

        attribute.subProducts.all().forEach((product) => {
          attributeValue[String(product.id)] = false
        })

        emptyAttributesArray.set(String(attribute.id), attributeValue)
      }
    })

    return emptyAttributesArray
  }
}

export default ProductResource
