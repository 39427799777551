class Surcharge {
  public price: number
  public quantity: number // same as quantity in OrderReceiptItem
  public title: string

  constructor(title: string, quantity: number, price: number) {
    this.title = title
    this.quantity = quantity
    this.price = price
  }
}

class DiscountItem {
  public price: number
  public quantity: number
  public title: string

  constructor(discountItem: any) {
    this.title = discountItem.title
    this.quantity = discountItem.quantity
    this.price = discountItem.price
  }
}

class OrderReceiptItem {
  public id: string
  public title: string
  public category: string
  public items: OrderReceiptItem[]
  public quantity: number
  public totalPrice: number
  public hasDiscount: boolean
  public instructions: string | null
  public totalPriceBeforeDiscount: number
  public supItems: Surcharge[]
  public depositItems: Surcharge[]
  public discountItems: DiscountItem[]

  constructor(data: any) {
    this.id = data.id
    this.title = data.title
    this.category = data.category
    this.items = (data.items ?? []).map((item: any) => new OrderReceiptItem(item))
    this.quantity = data.quantity
    this.totalPrice = data.total_price
    this.hasDiscount = data.has_discount
    this.instructions = data.instructions ?? null
    this.totalPriceBeforeDiscount = data.total_price_before_discount
    this.supItems = data.sup_items.map((sup: any) => new Surcharge(sup.title, sup.quantity, sup.price))
    this.depositItems = data.deposit_items.map((deposit: any) => new Surcharge(deposit.title, deposit.quantity, deposit.price))
    this.discountItems = data.discount_items.map((discount: any) => new DiscountItem(discount))
  }
}

class Tax {
  public amountExcludingTax: number
  public amountIncludingTax: number
  public summaryFormatted: string
  public tax: number
  public taxRate: number

  constructor(tax: any) {
    this.amountExcludingTax = tax.amount_excl_tax
    this.amountIncludingTax = tax.amount_incl_tax
    this.summaryFormatted = tax.summary_formatted
    this.tax = tax.tax
    this.taxRate = tax.tax_rate
  }
}

class OrderReceipt {
  public items: OrderReceiptItem[]
  public deliveryFee: number
  public giftcard: number
  public serviceFee: number
  public tip: number
  public deposit: number
  public instructions: string | null
  public sup: number
  public totalDiscount: number
  public subtotalDiscount: number
  public subtotal: number
  public total: number
  public taxes: Tax[]
  public transactionFee: number
  public supItems: Surcharge[]
  public depositItems: Surcharge[]
  public discountItems: DiscountItem[]

  constructor(receipt: any) {
    this.items = receipt.items.map((item: any) => new OrderReceiptItem(item))
    this.total = receipt.total
    this.taxes = receipt.taxes.map((tax: any) => new Tax(tax))
    this.deliveryFee = receipt.delivery_fee
    this.giftcard = receipt.fulfilled_by_giftcard
    this.serviceFee = receipt.service_fee
    this.tip = receipt.tip
    this.deposit = receipt.total_deposit_surcharge
    this.instructions = receipt.instructions ?? null
    this.totalDiscount = receipt.total_discount
    this.subtotalDiscount = receipt.subtotal_discount
    this.sup = receipt.total_sup_surcharge
    this.subtotal = receipt.subtotal
    this.transactionFee = receipt.transaction_fee
    this.supItems = receipt.sup_items.map((sup: any) => new Surcharge(sup.title, sup.quantity, sup.price))
    this.depositItems = receipt.deposit_items.map((deposit: any) => new Surcharge(deposit.title, deposit.quantity, deposit.price))
    this.discountItems = receipt.discount_items.map((discount: any) => new DiscountItem(discount))
  }
}

export {
  OrderReceipt,
  OrderReceiptItem,
}
