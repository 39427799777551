import UserResource from './UserResource'
import CustomerResource from './CustomerResource'
import { OrderReceipt } from '@/http/models/OrderReceipt'
import { Location } from '@/types/Location'

class OrderResource {
  constructor(order) {
    if (!order) return
    if ('raw' in order) order = order.raw

    this.id = order.id
    this.uuid = order.id
    this.hash = order.attributes.hash
    this.name = order.attributes.customer.name

    this.paymentStatus = order.attributes.payment_status
    this.status = order.attributes.status

    const establishment = order.relationships.establishment

    this.establishment = {
      id: establishment.id,
      title: establishment.attributes.title,
      location: {
        street: establishment.attributes.location.street,
        houseNumber: null, // see street
        zipcode: establishment.attributes.location.zipcode,
        city: establishment.attributes.location.city.slug,
        country: establishment.attributes.location.city.country.code,
        coordinates: {
          lat: establishment.attributes.location.lat,
          lng: establishment.attributes.location.lng,
        },
      },
    }

    this.user = new UserResource(order.attributes.customer)
    this.distributionType = order.attributes.distribution

    if (order.attributes.timeslot !== null) {
      this.distributionTime = {
        timeslotStart: order.attributes.timeslot.start,
        timeslotEnd: order.attributes.timeslot.end,
        date: order.attributes.distribution_at,
      }
    } else if (order.attributes.distribution_at !== null) {
      this.distributionTime = {
        timeslotStart: null,
        timeslotEnd: null,
        date: order.attributes.distribution_at,
      }
    } else {
      this.distributionTime = undefined
    }

    this.costs = {
      total: order.attributes.receipt.total,
      subtotal: order.attributes.receipt.subtotal,
      discount: order.attributes.receipt.fulfilled_by_giftcard,
      shipping: order.attributes.receipt.delivery_fee,
      tip: order.attributes.receipt.tip,
    }

    this.isAsap = order.attributes.is_asap
    this.isConfirmed = order.attributes.is_confirmed
    this.isAutoAcknowledged = order.attributes.is_auto_acknowledged
    this.isExpired = order.attributes.is_expired
    this.onLocationIdentifier = order.attributes.stand_id

    if (order.attributes.location !== undefined) {
      const location = order.attributes.location

      this.location = new Location({
        street: location.street,
        houseNumber: location.house_number,
        zipcode: location.zipcode,
        city: location.city,
        // country: location.city.country.code,
        coordinates: {
          lat: location.lat,
          lng: location.lng,
        },
      })
    }

    this.receipt = new OrderReceipt(order.attributes.receipt)

    this.customer = new CustomerResource(order.attributes.customer)
  }
}

export default OrderResource
