export const formatPrice = function (value) {
  const val = (value / 1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const ucfirst = function (value) {
  return value.replace(/^./, value[0].toUpperCase())
}

export const strtolower = function (value) {
  return value?.toLowerCase()
}

export const roundonedecimal = function (value) {
  return Math.round(value * 10) / 10
}

export const filters = [
  ['$formatPrice', formatPrice],
  ['$ucfirst', ucfirst],
  ['$strtolower', strtolower],
  ['$roundonedecimal', roundonedecimal],
]
