export type State = {
  marketingPage: any
  related: any
  ssr: boolean
}

export const marketing = {
  state: () => ({
    marketingPage: null,
    related: null,
    ssr: false,
  }),
  mutations: {
    SET_MARKETING_PAGE(state: State, marketingPage: any) {
      state.marketingPage = marketingPage
    },
    SET_RELATED(state: State, related: any) {
      state.related = related
    },
    SET_SSR(state: State, ssr: boolean) {
      state.ssr = ssr
    },
  },
  actions: {
    setMarketingPage({ commit }, marketingPage: any) {
      commit('SET_MARKETING_PAGE', marketingPage)
    },
    setRelated({ commit }, related: any) {
      commit('SET_RELATED', related)
    },
    setSSR({ commit }, ssr: boolean) {
      commit('SET_SSR', ssr)
    },
  },
}
