import OrderResource from '@/resources/OrderResource'

export const namespaced = true

export const state = () => ({
  orderData: null,
})

export const getters = {
  order: (state) => state.orderData !== null ? new OrderResource(state.orderData) : null,
}

export const actions = {
  async fetchOrder({ commit }, orderUuid) {
    const response = await this.$axios({
      method: 'get',
      url: `orders/${orderUuid}`,
    })

    commit('SET_ORDER', { orderData: response.data.data })
  },
}

export const mutations = {
  SET_ORDER: (state, { orderData }) => {
    state.orderData = orderData
  }
}
