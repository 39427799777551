enum AttributeType {
  Dropdown = 1,
  Checkbox = 2,
  OptionalDropdown = 3,
}

export function useProduct() {
  // TODO correct typing
  function calculatePriceWithAttributes(price: number, selectedAttributes: Map<string, any>, productAttributes: any[]) {
    let basicPrice = price

    for (const [attributeId, subProductIds] of selectedAttributes.entries()) {
      const attribute = productAttributes.find(({ id }) => String(id) === attributeId)

      attribute?.subProducts
        .filter(item => {
          if (attribute.type === AttributeType.Dropdown || attribute.type === AttributeType.OptionalDropdown) {
            // NB: in the case subProductIds is just ONE id
            return item.id === Number(subProductIds)
          } else if (attribute.type === AttributeType.Checkbox) {
            return subProductIds[item.id]
          }

          return false
        })
        .forEach(product => {
          // we found the product(s) of which we have to add the price!
          basicPrice += product.price
        })
    }

    return basicPrice
  }

  return {
    calculatePriceWithAttributes,
  }
}
