import { useSSRContext } from 'vue'

export function useRequestHost() {
  if (import.meta.env.SSR) {
    const ctx = useSSRContext()

    return ctx?.request?.headers.host
  }

  return new URL(window.location.href).host
}
