import ProductCollection from './ProductCollection'

class AttributeResource {
  constructor(data) {
    if (!data) return
    if ('raw' in data) data = data.raw

    this.id = data.id
    this.title = data.attributes.title
    this.type = data.attributes.type
    this.optional_placeholder = data.attributes.optional_placeholder
    this.order = data.attributes.order
    this.amount = data.attributes.amount

    this.subProducts = new ProductCollection().make(
      data?.relationships?.sub_products
    )

    this.raw = data
  }
}

export default AttributeResource
