import { isBefore, parse } from 'date-fns'
import { setCookie } from '@/utils/setCookie'

export const state = () => ({
  experiments: [],
})

export const getters = {
  experimentValue: (state) => (name) => {
    const experiment =
      state.experiments.find((experiment) => experiment.name === name) ?? null

    if (experiment === null) return null

    const expireDate = parse(experiment.date, 'yyyy-MM-dd HH:mm:ss', new Date())

    if (isBefore(expireDate, new Date())) return null

    return experiment.value
  },
}

const ADD_EXPERIMENT = 'ADD_EXPERIMENT'
const UPDATE_EXPERIMENT = 'UPDATE_EXPERIMENT'

export const actions = {
  addExperiment: ({ commit }, props) => {
    commit(ADD_EXPERIMENT, props)
  },

  updateExperiment: ({ commit, rootState }, { name, value, date }) => {
    commit(UPDATE_EXPERIMENT, { name, value, date })

    setCookie(name, value, rootState.session.country, { expires: date ?? getters.experimentValue(name).date })
  },
}

export const mutations = {
  [ADD_EXPERIMENT]: (state, { name, value, date }) =>
    state.experiments.push({ name, value, date }),

  [UPDATE_EXPERIMENT]: (state, { name, value, date }) => {
    const index = state.experiments.findIndex(
      (experiment) => experiment.name === name
    )

    if (index === -1) return state.experiments.push({ name, value, date })

    // or update the existing one
    state.experiments[index] = {
      name: state.experiments[index].name,
      value,
      date: date ?? state.experiments[index].date,
    }
  },
}
