import { ProductSet } from './ProductSet'
// import { Location } from '@/types/Location'

type Location = {
  street: string
  houseNumber: string | null
  zipcode: string
  city: string
  country: string
  coordinates: { lng: number; lat: number }
}

// Returns i18n translatable label | null
const constructLabelFromScore = (score: number) => {
  if (score >= 4.6 && score <= 5) return 'rating.excellent'
  if (score >= 4.2 && score <= 4.5) return 'rating.very-good'
  if (score >= 3.6 && score <= 4.1) return 'rating.good'
  if (score >= 0 && score <= 3.5) return null
}

enum DistributionType {
  TakeAway = 1,
  Delivery = 2,
  OnLocation = 3,
}

type Overlay = { canOrder: boolean, overlay: string }

export type Coordinates = { lat: number, lng: number }

type Company = {
  id: string
  name: string
}

type Category = {
  id: string
  name: string
}

export enum EstablishmentMode {
  Live = 'live',
  Test = 'test',
}

class Establishment {
  public id: string
  public slug: string
  public title: string
  public email: string
  public isClosed: boolean
  public showGoodWork: boolean
  public phone: string
  public description: string | string[]
  public url: string
  public mode: EstablishmentMode
  public location: Location
  public content: {
    content: string
    meta: {
      title: string
      description: string
    }
  }
  public pspIntegrationType: unknown
  public specialInstructionPlaceholder: unknown
  public instructions_mode: unknown
  public distributionTypes: DistributionType[]
  public avatar: string
  public cover: string
  public thumb: string
  public ageCheckRequired: boolean
  public reviews: {
    label: string | null
    amount: number
    average: number
    isNewOnBistroo: boolean
  }
  public company: Company
  public categories: Category[]
  public categoriesString: string
  public openingHours: unknown

  public state: string | null = null
  public minimalOrderValue: number | null = null
  public allowsAsap: boolean = false
  public overlay: Overlay | null = null
  public productSets: ProductSet[] | null = null
  public thumbOverlay: unknown
  public deliveryTime: number | null = null
  public prepTime: number | null = null
  public isWithinDeliveryArea = false
  public deliveryCosts: number | null = null
  public freeDeliveryStartingAt: number | null = null

  constructor(establishment: any) {
    this.id = establishment.id
    this.slug = establishment.slug
    this.title = establishment.title
    this.email = establishment.email
    this.isClosed = establishment.is_closed ?? true
    this.showGoodWork = establishment.show_platform_discount
    this.phone = establishment.phonenumber
    this.description =
      typeof establishment.description === 'string'
        ? establishment.description.split('\\n')
        : establishment.description
    this.url = establishment.url
    this.mode = establishment.mode
    this.pspIntegrationType = establishment.psp_integration_type
    this.specialInstructionPlaceholder = establishment.special_instruction_placeholder // special_instruction_placeholder
    this.distributionTypes = establishment.distributions
    this.avatar = establishment.media.images.avatar.medium.url !== '' ? establishment.media.images.avatar.medium.url : null
    this.cover = establishment.media?.images?.cover?.large?.url
    this.thumb = establishment.media?.images?.cover?.medium?.url
    this.ageCheckRequired = establishment.age_check_required

    this.location = {
      street: establishment.location.street,
      houseNumber: null, // see street
      zipcode: establishment.location.zipcode,
      city: establishment.location.city.slug,
      country: (establishment.location.city.country.code as string).toLowerCase(),
      coordinates: {
        lat: establishment.location.lat,
        lng: establishment.location.lng,
      },
    }

    this.content = {
      content: establishment.content.content,
      meta: {
        title: establishment.content.meta_title,
        description: establishment.content.meta_description,
      },
    }

    this.reviews = {
      label: constructLabelFromScore(establishment.reviews?.average_rating),
      amount: establishment.reviews?.count ?? 0,
      average: establishment.reviews?.average_rating ?? 0,
      isNewOnBistroo: establishment.reviews?.count <= 1,
    }

    this.company = {
      id: establishment.company.id,
      name: establishment.company.name,
    }

    this.categories = establishment.categories.map((category: any) => ({
      id: category.id,
      name: category.name,
    }))
    this.categoriesString = establishment.categories?.map(c => c.name).join(' • ')
    this.openingHours = establishment.opening_hours

    this.minimalOrderValue = establishment.minimal_order_value
    this.thumbOverlay = establishment.overlay.overlay ?? null
    this.deliveryTime = establishment.delivery_time
    this.state = establishment.state
    this.allowsAsap = establishment.allows_asap
    this.overlay = {
      canOrder: establishment.overlay.can_order,
      overlay: establishment.overlay.overlay
    }
    this.productSets = establishment.menu.product_sets.map((productSet: unknown) => new ProductSet(productSet))
    this.isWithinDeliveryArea = establishment.is_within_delivery_area
    this.minimalOrderValue = establishment.minimal_order_value
    this.prepTime = establishment.prep_time
    this.deliveryCosts = establishment.delivery_costs
    this.freeDeliveryStartingAt = establishment.free_delivery_starting_at
    this.instructions_mode = establishment.instructions_mode
  }

  public setDistributionInfo(establishmentDistribution: unknown) {
    this.minimalOrderValue = establishmentDistribution.minimal_order_value
    this.thumbOverlay = establishmentDistribution.overlay.overlay ?? null
    this.deliveryTime = establishmentDistribution.delivery_time
    this.state = establishmentDistribution.state
    this.allowsAsap = establishmentDistribution.allows_asap
    this.overlay = {
      canOrder: establishmentDistribution.overlay.can_order,
      overlay: establishmentDistribution.overlay.overlay
    }
    this.productSets = establishmentDistribution.menu.product_sets.map((productSet: unknown) => new ProductSet(productSet))
    this.isWithinDeliveryArea = establishmentDistribution.is_within_delivery_area
    this.minimalOrderValue = establishmentDistribution.minimal_order_value
    this.prepTime = establishmentDistribution.prep_time
    this.deliveryCosts = establishmentDistribution.delivery_costs
    this.freeDeliveryStartingAt = establishmentDistribution.free_delivery_starting_at
    this.instructions_mode = establishmentDistribution.instructions_mode
  }

  public logoCharacters() {
    const partitions = this.title.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').filter(value => value)
    return partitions ? `${partitions[0][0]}${partitions[1] ? partitions[1][0] : ''}` : ''
  }
}

export { Establishment, DistributionType, Company, Category }
