import { Collection } from 'collect.js'
import AttributeResource from '@/resources/AttributeResource'

class AttributeCollection extends Collection {
  make(items) {
    if (!items) return
    return super.make(
      items.map((attribute) => new AttributeResource(attribute))
    )
  }
}

export default AttributeCollection
