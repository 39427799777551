export type Slot = {
  available: boolean
  available_slots: number
  full: boolean
  label: string // 13:15 - 13:30
  time: string // 13:15
  timeslot: string // 13:15 - 13:30
}

export type Timeslot = {
  available: boolean
  date: string // 13-03-2024
  label: string // Woensdag 13 maart (vandaag)
  slots: Slot[]
  slots_available_count: number
  slots_count: number
}

type SelectedTimeSlot = {
  date: string | null
  time: string | null
}

export function selectFirstAvailableTimeSlot(dateSlots: Timeslot[], distributionDate: string | null, distributionTime: string | null): SelectedTimeSlot {
  if (dateSlots.length === 0) {
    return {
      date: null,
      time: null,
    }
  }

  let timeslot: Timeslot | undefined // because find returns undefined if not found

  if (distributionDate === null) {
    timeslot = dateSlots.find(slot => slot.available)
  } else {
    timeslot = dateSlots.find(slot => slot.date === distributionDate && slot.available)

    if (timeslot === undefined) {
      timeslot = dateSlots.find(slot => slot.available)
    }
  }

  distributionDate = timeslot?.date ?? null

  let slot: Slot | undefined

  if (distributionTime === null) {
    slot = timeslot?.slots.find(slot => slot.available)
  } else {
    slot = timeslot?.slots.find(slot => slot.time === distributionTime && slot.available)

    if (slot === undefined) {
      slot = timeslot?.slots.find(slot => slot.available)
    }
  }

  distributionTime = slot?.time ?? null

  return {
    date: distributionDate,
    time: distributionTime,
  }
}
