import { parsePhoneNumber } from 'libphonenumber-js/max'
import { formatISO } from 'date-fns'
import { DEFAULT_OPTIONS } from '../config'

// API factory for backend calls related to Cities

export default ($axios) => ({
  /*
   * login, logout, account and refresh are handled by @nuxtjs/auth and configured in nuxt.config.js
   */

  account: async (options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: 'profile',
      method: 'GET',
      ...options,
    })

    return data
  },

  register: async (
    { name, phoneNumber, email, password, passwordConfirmation },
    options = DEFAULT_OPTIONS
  ) => {
    const { data } = await $axios({
      url: 'auth/register',
      method: 'POST',
      data: {
        name,
        email,
        password,
        password_confirmation: passwordConfirmation,
        phone_number: phoneNumber,
      },
      ...options,
    })

    return data
  },

  resendEmail: async ({ email }, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: 'auth/email/request-verification',
      method: 'POST',
      data: {
        email,
      },
      ...options,
    })

    return data
  },

  recover: async ({ email }, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: 'auth/password/forgotten',
      method: 'POST',
      data: {
        email,
      },
      ...options,
    })

    return data
  },

  recoverPassword: async (
    { token, email, password, passwordConfirmation },
    options = DEFAULT_OPTIONS
  ) => {
    const { data } = await $axios({
      url: 'auth/password/reset',
      method: 'POST',
      data: {
        token,
        email,
        password: password,
        password_confirmation: passwordConfirmation,
      },
      ...options,
    })

    return data
  },

  updateAccount: async (
    { name, phoneNumber, email, birthday },
    options = DEFAULT_OPTIONS
  ) => {
    const dateOfBirth = birthday
      ? formatISO(birthday, { representation: 'date'})
      : null

    const { data } = await $axios({
      url: 'profile',
      method: 'PUT',
      data: {
        name,
        phone_number: phoneNumber,
        phone_number_country: parsePhoneNumber(phoneNumber).country,
        email,
        date_of_birth: dateOfBirth,
      },
      ...options,
    })

    return data
  },

  updatePassword: async (
    { currentPassword, newPassword, newPasswordConfirmation },
    options = DEFAULT_OPTIONS
  ) => {
    const { data } = await $axios({
      url: 'profile/password',
      method: 'PUT',
      data: {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation,
      },
      ...options,
    })

    return data
  },

  updateAddress: async (
    {
      id,
      title,
      street,
      zipcode,
      houseNumber,
      coordinates: { lat, lng },
      city,
      country,
    },
    options = DEFAULT_OPTIONS
  ) => {
    const { data } = await $axios({
      url: `profile/addresses/${id}`,
      method: 'PUT',
      data: {
        title,
        street_number: houseNumber,
        street_name: street,
        city,
        lat,
        lng,
        zipcode,
        country,
      },
      ...options,
    })

    return data
  },

  createAddress: async (
    {
      title,
      street,
      zipcode,
      houseNumber,
      coordinates: { lat, lng },
      city,
      countryCode = 'NL',
    },
    options = DEFAULT_OPTIONS
  ) => {
    const { data } = await $axios({
      url: 'profile/addresses',
      method: 'POST',
      data: {
        title,
        street_number: houseNumber,
        street_name: street,
        city,
        lat,
        lng,
        zipcode,
        country_code: countryCode,
      },
      ...options,
    })

    return data
  },

  deleteAddress: async ({ id }, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: `profile/addresses/${id}`,
      method: 'DELETE',
      ...options,
    })

    return data
  },

  /*
   * Orders
   */

  getOrders: async ({ page, perPage }, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: `profile/orders?page=${page}&per_page=${perPage}`,
      method: 'GET',
      ...options,
    })

    return data
  },

  getOrderById: async (id, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: `profile/orders/${id}`,
      method: 'GET',
      ...options,
    })

    return data
  },

  reorderOrder: async (id, options = DEFAULT_OPTIONS) => {
    const { data } = await $axios({
      url: `orders/${id}/reorder`,
      method: 'GET',
      ...options,
    })

    return data
  },

  async deleteAccount() {
    await $axios({
      url: 'profile',
      method: 'DELETE',
    })
  },
})
