import { Product } from './Product'

class ProductSet {
  public id: number
  public title: string
  public description: string
  public discount: {
    description: string
    amountString: string
  } | null = null
  public availableFrom: string | null
  public availableUntil: string | null
  public products: Product[] = []

  constructor(productSet: any) {
    this.id = productSet.id
    this.title = productSet.title
    this.description = productSet.description

    if (
      'discount' in productSet &&
      productSet.discount !== null &&
      productSet.discount.available
    ) {
      this.discount = {
        description: productSet.discount.description,
        amountString: productSet.discount.amount_string,
      }
    }

    this.availableFrom = productSet.available_from
    this.availableUntil = productSet.available_until

    this.products = productSet.products.map((product: any) => new Product(product))
  }
}

export {
  ProductSet,
}
