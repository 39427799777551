class CategoryResource {
  constructor(category) {
    if ('raw' in category) category = category.raw

    this.id = category.id
    this.value = category.id
    this.name = category.attributes.name
    this.thumb = category.attributes.thumbnail_url
    this.header = category.relationships
      ? category.relationships.media.images.header
      : {}
    this.slug = category.attributes.slug
    this.subtitle = category.attributes.subtitle
    this.meta = {
      title: category.attributes.meta_title,
      description: category.attributes.meta_description,
    }

    this.parent = category.attributes.parent
    this.content = category.attributes.content || {}

    this.raw = category
  }
}

export default CategoryResource
