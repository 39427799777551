const SET_CATEGORIES_COUNT = 'SET_CATEGORIES_COUNT'
const SET_SSR = 'SET_SSR'

export const homepage = {
  state: () => ({
    ssr: false,
    categories: 0,
  }),
  mutations: {
    [SET_CATEGORIES_COUNT]: (state, count) =>
      (state.categories = count),
    [SET_SSR]: (state, ssr) =>
      (state.ssr = ssr),
  },
  actions: {
    setCategoriesCount({ commit }, count) {
      commit(SET_CATEGORIES_COUNT, count)
    },
    setSSR({ commit }, ssr) {
      commit(SET_SSR, ssr)
    },
  },
}
