import BasketClient from './clients/Basket'
import EstablishmentClient from './clients/Establishment'
import OrderClient from './clients/Order'
import LocationClient from './clients/Location'
import CustomerClient from './clients/Customer'

export default ({ $axios }, inject) => {
  const clients = {
    basket: BasketClient($axios),
    establishment: EstablishmentClient($axios),
    order: OrderClient($axios),
    location: LocationClient($axios),
    customer: CustomerClient($axios),
  }

  inject('api', clients)
}
