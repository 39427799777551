// @ts-ignore
import Cookies from 'js-cookie'
import { type CookieAttributes } from 'js-cookie'

export function setCookie(name: string, value: string, country: string, options?: CookieAttributes) {
  const url = new URL(globalThis.location.href)

  if (url.host.endsWith('.com')) {
    options = {
      path: `/${country}`,
      ...options ?? {},
    }
  }

  Cookies.set(name, value, options ?? {})
}
