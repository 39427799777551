import type { InjectionKey } from 'vue'
// eslint-disable-next-line no-restricted-imports
import { type Store, createStore as _createStore, useStore as _useStore } from 'vuex'
import { VuexPersistence } from 'vuex-persist'
import * as session from '../store/session'
import * as modals from '../store/modals'
import * as modal from '../store/modal'
import { establishment, type State as EstablishmentState } from './establishment'
import { homepage } from './homepage'
import * as cart from '../store/cart'
import * as user from '../store/user'
import * as components from '../store/components'
import * as snackbar from '../store/snackbar'
import * as experiments from '../store/experiments'
import { serviceAlert } from './serviceAlert'
import { marketplace, type State as MarketplaceState } from './marketplace'
import { slug, type State as SlugState } from './slug'
import { marketing, type State as MarketingState } from './marketing'
import { allCities, type State as AllCitiesState } from './allCities'
import { category } from '../stores/category'
import type { Location } from '@/types/Location'
import type { DistributionType } from '@/http/models/Establishment'

// TODO: type the store
export type State = {
  session: {
    country: string
    locale: string
    onLocationIdentifier: string | null
    currentLocation: Location | null
    temporaryLocation: Location | null
    distributionType: DistributionType | null
    onLocationDirect: boolean
  }
  // TODO: move into type
  cart: {
    cartSyncing: boolean
    timeslots: any[]
    loadedTimelots: boolean
    basketInfo: {
      establishment: {
        city: {
          country: {
            id: number
            code: string
          }
          id: number
          name: string
          slug: string
        }
        id: string
        name: string
        slug: string
      }
      costs: {
        total_payable: number
      }
    } | null
    basketItems: any[]
    establishment: any
    distributionType: DistributionType | null
    orderUuid: string | null
    currentView: string
  }
  establishment: EstablishmentState
  marketplace: MarketplaceState
  slug: SlugState
  marketing: MarketingState
  allCities: AllCitiesState
  user: {
    user: {
      phone: string
      name: string
      email: string
    }
    guest: {
      phone: string
      name: string
      email: string
    }
  }
}

const vuexLocal = new VuexPersistence<State>({
  key: 'bistroo-v7',
  supportCircular: true,
  reducer: state => {
    const { cartSyncing, loadedTimelots, timeslots, currentView, establishment, ...cart } = state.cart
    const { fetching, ...user } = state.user
    const { distributionType, currentLocation, selectedCategories, selectedSortingKey, locale, country, ...session } = state.session

    return {
      cart,
      session,
      user,
    }
  },
})

const stores = {
  session,
  modals,
  modal,
  establishment,
  cart,
  category,
  user,
  components,
  snackbar,
  experiments,
  homepage,
  serviceAlert,
  marketplace,
  slug,
  marketing,
  allCities,
}

export function createStore() {
  const modules = {}

  for (const namespace in stores) {
    modules[namespace] = {
      namespaced: true,
      ...stores[namespace],
    }
  }

  return _createStore<State>({
    modules,
    devtools: false,
    plugins: [vuexLocal.plugin],
  })
}

export const key: InjectionKey<Store<State>> = Symbol()

export function useStore() {
  return _useStore(key)
}
