export default ({ app }, fetchState) => {
  return async function (ctx, config) {

    fetchState.pending = true
    fetchState.timestamp = Date.now()
    fetchState.error = null

    try {
      return await fetch(config)
    } catch (e) {
      fetchState.error = e
    } finally {
      fetchState.pending = false
    }
  }
}
