type Content = {
  title: string
  subtitle: string
  content: string
  meta: {
    title: string | null
    description: string | null
  }
}

class City {
  name: string
  slug: string
  content: Content

  constructor(city: any) {
    this.name = city.attributes.name
    this.slug = city.attributes.slug
    this.content = city.attributes.content
  }
}

export { City }
