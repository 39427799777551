export const ignoreErrors: (string | RegExp)[] = [
  'Non-Error promise rejection captured',
  'Unable to preload CSS for',
  'Failed to fetch dynamically imported module',
  'Importing a module script failed',
  'Blocked a frame with origin',
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
  /Request failed with status code 4\d{2}/,
  'Network Error',
]
