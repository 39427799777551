import { Collection } from 'collect.js'
import CategoryResource from '@/resources/CategoryResource'

class CategoryCollection extends Collection {
  make(items) {
    if (!items) return
    return super.make(items.map((attribute) => new CategoryResource(attribute)))
  }
}

export default CategoryCollection
