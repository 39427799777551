import { computed } from 'vue'
import { useStore } from '@/stores'
import { useGtm } from '@gtm-support/vue-gtm'
import { useProduct } from '@/composables/product'
import { useSentry } from '@/plugins/sentry'
import { MarketplaceEstablishment } from '@/http/models/MarketplaceEstablishment'
import type { Establishment } from '@/http/models/Establishment'

const EVENTS = Object.freeze({
  ADDRESS_SEARCHED: 'address_searched',
  ADDRESS_SEARCHED_LOCATION_PIN: 'address_searched_location_pin',
  ADDRESS_SEARCHED_PIN_DROP: 'address_searched_pin_drop',
  ADDRESS_SELECTED: 'address_selected',
  VIEW_ESTABLISHMENT_LIST: 'view_establishment_list',
  VIEW_ESTABLISHMENT_LIST_SCROLL: 'view_establishment_list_scroll',
  VIEW_ESTABLISHMENT: 'view_establishment',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  EMPTY_CART: 'empty_cart',
  SEARCH: 'search',
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  ACCOUNT_VERIFIED: 'account_verified',
  ADD_SHIPPING_ORDER: 'add_shipping_info',
  ADD_PERSONAL_INFO: 'add_personal_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  PURCHASE: 'purchase',
})

export const ViewType = {
  Marketplace: { id: 1, name: 'Marketplace' },
  City: { id: 2, name: 'City page' },
  Cuisine: { id: 3, name: 'Cuisine page' },
  Marketing: { id: 4, name: 'Marketing page' },
} as const

type ViewTypes = typeof ViewType[keyof typeof ViewType]

const formatProductSet = (productGroups) => productGroups.map((productGroup, index) => ({
  index,
  productgroup_id: productGroup.id,
  productgroup_name: productGroup.title,
  coupon: productGroup.discount?.description,
  discount: productGroup.discount?.amountString,
}))

function useGa({ gtm, store, sentry } = { gtm: useGtm(), store: useStore(), sentry: useSentry() }) {
  const userLocation = computed(() => store.getters['session/location'])
  const cartCoupon = computed(() => store.getters['cart/couponCode'])
  const distributionTypeCart = computed(() => store.getters['cart/distributionType'])
  const distributionTypeSession = computed(() => store.getters['session/distributionType'])

  const paymentPspIntegrationType = computed(() => store.getters['cart/establishment']?.pspIntegrationType)
  const paymentMethod = computed(() => store.getters['cart/paymentMethod'])

  const formatProducts = ({ establishment, products, useCartProductFormat = false }) => {
    const { calculatePriceWithAttributes } = useProduct()

    const productsOfEstablishment = establishment.productSets.reduce((acc, productGroup) => [...acc, ...productGroup.products], [])

    sentry?.addBreadcrumb({
      message: 'formatProducts in useGa',
      data: {
        establishment,
        products,
        useCartProductFormat,
      },
    })

    return products.map(({ product, amount, selectedAttributes }) => {
      const productInEstablishment = productsOfEstablishment.find(({ id }) => id === product.id)
      const price = useCartProductFormat ? product.price_after_discount : calculatePriceWithAttributes(product.price, selectedAttributes, product.attributes)

      const index = productsOfEstablishment.findIndex((p) => p.id === product.id)
      const productGroup = establishment.productSets.find((productGroup) => productGroup.products.find((p) => p.id === product.id))

      const attributeGroupIds = useCartProductFormat
        ? product.sub_product_groups.map(({ id }) => id)
        : product.attributes.map((attribute) => attribute.id)

      const attributeGroupNames = useCartProductFormat
        ? product.sub_product_groups.map(({ id }) => id)
        : product.attributes.map(({ title }) => title)

      // This is (to my regrets) a quite complex function
      // It maps the selected attributes to the product's sub products
      const createVariantArrayFromProductResource = (productResource) => productResource.attributes
        .map(({ id, subProducts }) => {
          const allSubProducts = subProducts

          // A decision was made to use a map of either strings or objects to represent the selected attributes
          // This results in the quite complex logic below.
          return subProducts
            .map(({ id: subProductId, title }) => {

              // Note: The map is inconsistent between number / string keys, we often convert numbers to strings to make matching happen
              const selected = selectedAttributes.get(id + '') || selectedAttributes.get(id)

               // if the selected attribute was the optional placeholder (f/e geen drankje)
              // it wasn't added and we return null instead of trying to match it
              if (selected === undefined) return null

              if (typeof selected  === 'string') return selected === subProductId + '' ? title : null

              const selectedArray = Object.entries(selected).filter(([key, value]) => key === (subProductId + '') && value)

              return selectedArray.reduce((acc, [subProductOptionId]) => {
                const { title } = allSubProducts.find(({ id }) => id + '' === subProductOptionId + '')
                return [acc, title].filter(Boolean).join(', ')
              }, null)
            }).filter(Boolean)
          }
        )

      const itemVariant = useCartProductFormat
        ? product.sub_product_groups.map((subProductGroup) => subProductGroup.sub_products.map(({ title }) => title).join(','))
        : createVariantArrayFromProductResource(product)

      return {
        index,
        price,
        quantity: amount,

        item_id: product.id,
        item_name: product.title,
        item_brand: establishment.title,
        item_category: productGroup.title,

        item_list_id: attributeGroupIds,
        item_list_name: attributeGroupNames,
        item_variant: itemVariant,

        coupon: productInEstablishment.discount?.description,
        discount: product.discount,

        location_id: establishment.location.city,
        has_image: productInEstablishment.images ? !!productInEstablishment.images?.cover?.srcSet : undefined,
      }
    })
  }

  const productsInCart = computed(() => {
    const establishment = store.getters['cart/establishment']
    const products = store.getters['cart/basketItems']

    return formatProducts({
      establishment,
      products: products.all(),
      useCartProductFormat: true,
    })
  })

  const cartValue = computed(() =>
    productsInCart.value.reduce((acc, {price, quantity}) => acc + price * quantity, 0)
  )

  // https://trackingchef.com/google-tag-manager/resetting-the-data-layer-in-google-tag-manager/
  function resetDatalayer() {
    window.dataLayer?.push(function() {
      this.reset()
    })
  }

  const track = (event, data = {}) => {
    if (event !== null && !Object.values(EVENTS).includes(event)) throw new Error('Unknown GA event')

    gtm?.trackEvent({ event, ...data })
  }

  const trackSearch = ({ query, count }) => {
    track(EVENTS.SEARCH, {
      search_param: {
        search_term: query,
        dist_type: distributionTypeSession.value,
        location_id: userLocation.value?.city,
      },
      result_count: count,
    })
  }

  function trackViewEstablishmentList(type: ViewTypes, establishments: any[], totalEstablishmentCount: number) {
    resetDatalayer()

    track('view_establishment_list', {
      establishment_list_id: type.id,
      establishment_list_name: type.name,
      establishment_count: establishments.length,
      establishment_count_total: totalEstablishmentCount,
      establishments: establishments.map((establishment, index) => {
        const resource = new MarketplaceEstablishment(establishment)
        const categories = resource.categories

        return {
          index,
          establishment_id: resource.id,
          establishment_name: resource.title,
          establishment_brand: resource.company.name,
          establishment_brand_id: resource.company.id,
          location_id: resource.city.name,
          coupon: resource.meta?.activeDiscount?.formattedValue,
          discount: resource.meta?.activeDiscount?.formattedValue,
          establishment_category: categories[0]?.name,
          establishment_category2: categories[1]?.name,
          establishment_category3: categories[2]?.name,
          establishment_category4: categories[3]?.name,
          establishment_category5: categories[4]?.name,
        }
      }),
    })
  }

  function trackViewEstablishmentListScroll(establishments: any[], currentEstablishmentCount: number, totalEstablishmentCount: number, page = 1) {
    track('view_establishment_list_scroll', {
      establishment_list_id: 1,
      establishment_list_name: 'Marketplace',
      establishment_count: currentEstablishmentCount,
      establishment_count_total: totalEstablishmentCount,
      page,
      establishments: establishments.map((establishment, index) => {
        const resource = new MarketplaceEstablishment(establishment)
        const categories = resource.categories

        return {
          index,
          establishment_id: resource.id,
          establishment_name: resource.title,
          establishment_brand: resource.company.name,
          establishment_brand_id: resource.company.id,
          location_id: resource.city.name,
          coupon: resource.meta?.activeDiscount?.formattedValue,
          discount: resource.meta?.activeDiscount?.formattedValue,
          establishment_category: categories[0]?.name,
          establishment_category2: categories[1]?.name,
          establishment_category3: categories[2]?.name,
          establishment_category4: categories[3]?.name,
          establishment_category5: categories[4]?.name,
        }
      }),
    })
  }

  function trackViewEstablishment(establishment: Establishment) {
    resetDatalayer()

    track(EVENTS.VIEW_ESTABLISHMENT, {
      establishment_id: establishment.id,
      establishment_name: establishment.title,
      establishment_brand: establishment.company.name,
      establishment_brand_id: establishment.company.id,
      establishment_type: 'restaurant',
      location_id: establishment.location.city,
      ecommerce: {
        productgroups: formatProductSet(establishment.productSets)
      }
    })
  }

  const trackProductEvent = (event, { product, amount, establishment, selectedAttributes }) => {
    const items = formatProducts({
      establishment,
      products: [{ product, amount, selectedAttributes }],
    })

    const value = items.reduce((acc, {price, quantity}) => acc + price * quantity, 0)

    resetDatalayer()

    track(event, {
      ecommerce: {
        currency: 'EUR',
        value,
        items,
      }
    })
  }

  const trackViewProduct = (data) => trackProductEvent(EVENTS.VIEW_ITEM, {
    amount: 1,
    selectedAttributes: [],
    ...data,
  })

  const trackViewCart = () => {
    resetDatalayer()

    track(EVENTS.VIEW_CART, {
      ecommerce: {
        currency: 'EUR',
        coupon: cartCoupon.value,
        value: cartValue.value,
        items: productsInCart.value,
      }
    })
  }

  const trackAddShippingInfo = () => {
    resetDatalayer()

    track(EVENTS.ADD_SHIPPING_ORDER, {
      ecommerce: {
        currency: 'EUR',
        coupon: cartCoupon.value,
        value: cartValue.value,
        items: productsInCart.value,
        shipping_tier: distributionTypeCart.value,
      }
    })
  }

  const trackAddPersonaInfo = () => {
    resetDatalayer()

    track(EVENTS.ADD_PERSONAL_INFO, {
      ecommerce: {
        currency: 'EUR',
        coupon: cartCoupon.value,
        value: cartValue.value,
        items: productsInCart.value,
      }
    })
  }

  const trackAddPaymentInfo = () => {
    resetDatalayer()

    track(EVENTS.ADD_PAYMENT_INFO, {
      ecommerce: {
        currency: 'EUR',
        coupon: cartCoupon.value,
        value: cartValue.value,
        payment_type: [paymentPspIntegrationType.value, paymentMethod.value.name],
        items: productsInCart.value,
      }
    })
  }

  function trackPurchase(order) {
    resetDatalayer()

    track(EVENTS.PURCHASE, {
      ecommerce: {
        currency: 'EUR',
        id: order.uuid,
        transaction_id: order.hash,
        value: order.receipt.total,
        tax: order.receipt.taxes.reduce((acc, { tax }) => acc + tax, 0),
        shipping: order.receipt.deliveryFee,
        discount: order.receipt.totalDiscount,
        tip: order.receipt.tip,
        dist_type: order.distributionType,
        coupon: order.receipt.discountItems.map(({ title }) => title),
        items: order.receipt.items.map((item, index) => ({
          item_id: item.id, // id is always undefined?
          item_name: item.title,
          // coupon
          index,
          item_brand: order.establishment.title,
          item_category: item.category,
          // item_list_id
          // item_list_name
          // item_variant: item.items.all().map(({ title }) => title),
          discount: item.totalPriceBeforeDiscount - item.totalPrice,
          location_id: order.establishment.location.city,
          price: item.totalPrice,
          quantity: item.quantity,
        })),
      },
    })
  }

  return {
    trackViewEstablishmentList,
    trackViewEstablishmentListScroll,
    trackViewEstablishment,
    trackViewCart,

    // Product events
    trackViewProduct,
    trackAddProduct: (data) => trackProductEvent(EVENTS.ADD_TO_CART, data),
    trackRemoveProduct: (data) => trackProductEvent(EVENTS.REMOVE_FROM_CART, data, { useCartProductFormat: true }),
    trackEmptyCart: () => track(EVENTS.EMPTY_CART, { ecommerce: null }),

    // Search
    trackSearch,

    // Address
    trackAddressSelected: () => track(EVENTS.ADDRESS_SELECTED),
    trackAddressSearched: () => track(EVENTS.ADDRESS_SEARCHED),
    trackAddressSearchedPinDrop: () => track(EVENTS.ADDRESS_SEARCHED_PIN_DROP),
    trackAddressSearchedLocationPin: () => track(EVENTS.ADDRESS_SEARCHED_LOCATION_PIN),

    // checkout
    trackAddShippingInfo,
    trackAddPersonaInfo,
    trackAddPaymentInfo,

    // thanks
    trackPurchase,

    // Account
    trackSignUp: () => track(EVENTS.SIGN_UP, { login_param: { method: 'manual' }}),
    trackAccountVerified: () => track(EVENTS.ACCOUNT_VERIFIED),
    trackLogin: () => track(EVENTS.LOGIN),
  }
}

export {
  useGa,
}
