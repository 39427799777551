// TODO move to generic place
type MediaType = {
  large: {
    url: string
  }
  medium: {
    url: string
  }
  small: {
    url: string
  }
}

export type MarketplaceMarketingEstablishmentCity = {
  id: number
  name: string
  slug: string
  country: string
}

export type EstablishmentReviews = {
  averageRating: number
  count: number
}

type MarketplaceEstablishmentCategory = {
  id: string
  name: string
}

export type Media = {
  images: {
    avatar: MediaType
    cover: MediaType
  }
}

type MarketplaceEstablishmentMeta = {
  address: string
  cardOverlay: { icon: string, text: string } | null
  isExclusive: boolean
  minimalOrderValue: null
  nextOpen: Date
  prepTime: string // "30 min."
  distance: string | null
  deliveryCosts: string | null
  activeDiscount: {
    value: number
    formattedValue: string
    valueType: string
  } | null
}

class MarketplaceEstablishment {
  id: string
  slug: string
  // eslint-disable-next-line quotes
  state: "opened" | "closed" | "opens_later_today" | "allows_preorder" | null
  title: string
  url: string
  reviews: EstablishmentReviews
  categories: MarketplaceEstablishmentCategory[]
  city: MarketplaceMarketingEstablishmentCity
  company: { id: string, name: string }
  media: Media
  meta: MarketplaceEstablishmentMeta | null = null

  constructor(data: any) {
    this.id = data.id
    this.slug = data.slug
    this.state = data.state ?? null
    this.title = data.title
    this.url = data.url
    this.reviews = {
      averageRating: data.reviews.average_rating,
      count: data.reviews.count,
    }
    this.city = {
      id: data.city.id,
      name: data.city.name,
      slug: data.city.slug,
      country: data.city.country.code,
    }
    this.company = {
      id: data.company.id,
      name: data.company.name,
    }
    this.categories = data.categories.map((category: any) => {
      return {
        id: category.id,
        name: category.name,
      }
    })
    this.media = {
      images: {
        avatar: {
          large: {
            url: data.media.images.avatar.large.url,
          },
          medium: {
            url: data.media.images.avatar.large.url,
          },
          small: {
            url: data.media.images.avatar.large.url,
          },
        },
        cover: {
          large: {
            url: data.media.images.cover.large.url,
          },
          medium: {
            url: data.media.images.cover.medium.url,
          },
          small: {
            url: data.media.images.cover.small.url,
          },
        },
      },
    }

    if (data.meta !== undefined) {
      this.meta = {
        address: data.meta.address,
        cardOverlay: data.meta.card_overlay,
        isExclusive: data.meta.is_exclusive,
        minimalOrderValue: data.meta.minimal_order_value,
        nextOpen: new Date(data.meta.next_open), // TODO format
        prepTime: data.meta.prep_time,
        distance: data.meta.distance ?? null,
        deliveryCosts: data.meta.delivery_costs ?? null,
        activeDiscount: data.meta.active_discount === undefined ? null : {
          formattedValue: data.meta.active_discount.formatted_value,
          value: data.meta.active_discount.value,
          valueType: data.meta.active_discount.value_type,
        },
      }
    }
  }
}

export {
  MarketplaceEstablishment,
}
