const createDraggable = (
  element,
  {
    closingDistance,
    direction = 'horizontal',
    closable = true,
    transparentize = true,
    transition = '0.2s',
  } = {}
) => {
  let startPosition = 0
  let active = false

  const percentage = (number, desiredPercentage) =>
    (number * desiredPercentage) / 100

  // Provide default values for closingDistance
  closingDistance =
    closingDistance || direction === 'horizontal'
      ? percentage(element.getBoundingClientRect().width, 40)
      : percentage(element.getBoundingClientRect().height, 50)

  const axis = direction === 'horizontal' ? 'X' : 'Y'

  const disableDocumentDrag = (e) => e.preventDefault()

  const clamp = (number, min, max) => Math.min(Math.max(number, min), max)

  /*
   * Methods
   */

  const start = (e) => {
    document.addEventListener('touchmove', disableDocumentDrag, {
      passive: false,
    })

    // Clear animated transitions
    element.style.transition = ''

    // register whether the mouse is down
    active = true
    startPosition =
      'changedTouches' in e
        ? e.changedTouches[0][`screen${axis}`]
        : e[`client${axis}`]

    // Switch attributes
    element.removeAttribute('draggable-released', true)
    element.setAttribute('draggable-active', true)
  }

  const move = (e) => {
    // short out on mousemove if mouse is not clicked-and-hold
    if (!active) return

    const currentPosition =
      'changedTouches' in e
        ? e.changedTouches[0][`screen${axis}`]
        : e[`client${axis}`]

    const movement = currentPosition - startPosition

    // clamp the transparency between 0.1 and 1
    const transparency = clamp(1 - Math.abs(movement) / closingDistance, 0.1, 1)

    // disable on modals that cannot be closed
    if (!closable) return

    // animate dragging down
    element.style.transform = `translate${axis}(${movement}px)`
    element.style.opacity = transparentize ? transparency : 1
  }

  const end = (e) => {
    active = false

    // Enable document scroll again
    document.removeEventListener('touchmove', disableDocumentDrag)

    // Enable transitions
    element.style.transition = `all ${transition}`

    const endPosition =
      'changedTouches' in e
        ? e.changedTouches[0][`screen${axis}`]
        : e[`client${axis}`]

    const movement = endPosition - startPosition

    element.removeAttribute('draggable-active', true)
    element.setAttribute('draggable-released', true)

    if (
      // check if the distance is sufficient enough
      Math.abs(movement) >= closingDistance &&
      // check if this modal can be closed
      closable
    ) {
      const direction = endPosition > startPosition ? 1 : -1
      element.style.transform = `translate${axis}(${
        element.getBoundingClientRect().width * direction
      }px)`

      element.style.opacity = 0

      // Dispatch a draggable-close event
      element.dispatchEvent(new Event('draggable-close'))
    } else {
      element.style.transform = `translate${axis}(0px)`
      element.style.opacity = 1
    }
  }

  document.addEventListener(
    'mouseout',
    (e) => {
      const from = e.relatedTarget || e.toElement
      if (active && (!from || from.nodeName == 'HTML')) end(e)
    },
    false
  )

  return { start, move, end }
}

const directive = {
  mounted: (element, binding) => {
    const { start, move, end } = createDraggable(element, binding.value)

    // Touch support
    element.addEventListener('touchstart', start)
    element.addEventListener('touchmove', move)
    element.addEventListener('touchend', end)

    // Mouse support
    element.addEventListener('mousedown', start)
    element.addEventListener('mousemove', move)
    element.addEventListener('mouseup', end)
  },
}

export default directive
