import collect from 'collect.js'
import { Location } from '@/types/Location'
import ProductSetCollection from './ProductSetCollection'
import CategoryCollection from './CategoryCollection'
import DistributionTypes from '@/config/DistributionTypes'

// Returns i18n translatable label | null
const constructLabelFromScore = (score) => {
  if (score >= 4.6 && score <= 5) return 'rating.excellent'
  if (score >= 4.2 && score <= 4.5) return 'rating.very-good'
  if (score >= 3.6 && score <= 4.1) return 'rating.good'
  if (score >= 0 && score <= 3.5) return null
}

class EstablishmentResource {
  constructor(establishment) {
    if (!establishment) return
    if ('raw' in establishment) establishment = establishment.raw

    /* Compact Establishment attributes */
    this.id = establishment.id
    this.slug = establishment.attributes.slug
    this.name = establishment.attributes.title

    if (establishment.attributes.content) {
      this.content = {
        content: establishment.attributes.content.content,
        meta: {
          title: establishment.attributes.content.meta_title,
          description: establishment.attributes.content.meta_description,
        },
      }
    }

    this.isWithinDeliveryArea = establishment.attributes.is_within_delivery_area
    this.ageCheckRequired = establishment.attributes.age_check_required
    this.state = establishment.attributes.state
    this.phone = establishment.attributes.phonenumber
    this.email = establishment.attributes.email

    /* Media */
    this.avatar =
      establishment.relationships?.media?.images?.avatar?.medium?.url
    this.cover = establishment.relationships?.media?.images?.cover?.large?.url
    this.thumb = establishment.relationships?.media?.images?.cover?.medium?.url

    this.overlay = establishment.attributes.overlay ?? null

    /* All establishment.attributes */
    this.description =
      typeof establishment.attributes.description === 'string'
        ? establishment.attributes.description.split('\\n')
        : establishment.attributes.description

    this.pspIntegrationType = establishment.attributes.psp_integration_type

    this.phone = establishment.attributes.phonenumber
    this.email = establishment.attributes.email

    this.citySlug = establishment.attributes.location?.city_slug

    this.location = new Location(establishment.attributes.location)

    /* Distribution types */
    this.distributionTypes = establishment.attributes.distributions
      ? [
          DistributionTypes.TakeAway,
          DistributionTypes.Delivery,
          DistributionTypes.OnLocation,
        ].filter((type) => {
          return establishment.attributes.distributions.includes(type)
        })
      : []

    /* Menu */
    this.productSets = establishment.relationships?.menu?.relationships
      ?.product_sets
      ? new ProductSetCollection().make(
          establishment.relationships.menu.relationships.product_sets
        )
      : collect()

    this.categories = establishment.relationships ? new CategoryCollection().make(
      establishment.relationships.categories
    ) : collect()

    this.categoriesString = this.categories?.all().map((cat) => cat.name).join(' • ')

    /* Reviews */
    this.reviews = {
      label: constructLabelFromScore(
        establishment.attributes.reviews?.average_rating
      ),
      amount: establishment.attributes.reviews?.count || 0,
      average: establishment.attributes.reviews?.average_rating || 0,
      isNewOnBistroo: establishment.attributes.reviews?.count <= 1,
    }

    this.mode = establishment.attributes.mode

    /* Meta items */
    this.meta = establishment.attributes.meta

    /* discount */
    this.discount = establishment.attributes.meta?.active_discount
      ? {
          value: establishment.attributes.meta.active_discount.value,
          formatted:
            establishment.attributes.meta.active_discount.formatted_value,
          type: establishment.attributes.meta.active_discount.type,
        }
      : null

    this.freeDeliveryStartingAt =
      establishment.attributes.free_delivery_starting_at

    this.deliveryLow = establishment.attributes.delivery_low
    this.deliveryCosts = establishment.attributes.delivery_costs

    /* Overlay */
    this.thumbOverlay = establishment.attributes.meta?.card_overlay || null

    /* Opening hours */
    this.openingHours = establishment.attributes.opening_hours

    /* Empty price rules */
    this.priceRules = collect()

    this.company = establishment.relationships?.company

    this.showGoodWork = establishment.attributes.show_platform_discount ?? false

    this.isClosed = establishment.attributes.is_closed ?? true

    this.special_instruction_placeholder = establishment.attributes.special_instruction_placeholder

    /* Raw */
    this.raw = establishment
  }

  // On search results, only return meta items that are concerned with time
  metaForSearch() {
    return this.meta
  }
}

export default EstablishmentResource
