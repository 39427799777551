import { Establishment } from '@/http/models/Establishment'

const SET_ESTABLISHMENT = 'SET_ESTABLISHMENT'
const SET_ESTABLISHMENT_DISTRIBUTION = 'SET_ESTABLISHMENT_DISTRIBUTION'
const SET_SSR = 'SET_SSR'
const SET_FAQ = 'SET_FAQ'
const SET_PRICE_RULES = 'SET_PRICE_RULES'
const SET_REVIEWS = 'SET_REVIEWS'

export type State = {
  ssr: boolean
  establishment: object | null
  faq: object[]
  priceRules: object[] | null
  reviews: object[] | null
}

export const establishment = {
  state: () => ({
    ssr: false,
    establishment: null,
    faq: [],
    priceRules: null,
    reviews: null,
  }),
  mutations: {
    [SET_ESTABLISHMENT]: (state: State, establishment: object) => (state.establishment = establishment),
    [SET_ESTABLISHMENT_DISTRIBUTION]: (state: State, establishmentDistribution: object) => {
      if (state.establishment !== null) {
        state.establishment = {
          ...state.establishment,
          ...establishmentDistribution,
        }
      }
    },
    [SET_FAQ]: (state: State, faq) =>
      (state.faq = faq),
    [SET_PRICE_RULES]: (state: State, priceRules: object[]) =>
      (state.priceRules = priceRules),
    [SET_REVIEWS]: (state: State, reviews: object[]) =>
      (state.reviews = reviews),
    [SET_SSR]: (state: State, ssr: boolean) =>
      (state.ssr = ssr),
  },
  actions: {
    setEstablishment({ commit }, establishment: object) {
      commit(SET_ESTABLISHMENT, establishment)
    },
    setSSR({ commit }, ssr: boolean) {
      commit(SET_SSR, ssr)
    },
    setEstablishmentDistribution({ commit }, establishmentDistribution: object) {
      commit(SET_ESTABLISHMENT_DISTRIBUTION, establishmentDistribution)
    },
    setEstablishmentFaq({ commit }, faq) {
      commit(SET_FAQ, faq)
    },
    setPriceRules({ commit }, priceRules) {
      commit(SET_PRICE_RULES, priceRules)
    },
    storeReviews({ commit }, reviews: object[]) {
      commit(SET_REVIEWS, reviews)
    },
    resetState({ commit }) {
      commit(SET_ESTABLISHMENT, null)
      commit(SET_FAQ, [])
      commit(SET_PRICE_RULES, null)
    },
  },
  getters: {
    establishment: (state: State) => state.establishment !== null ? new Establishment(state.establishment) : null,
    faq: (state: State) => state.faq,
    reviews: (state: State) => state.reviews,
  },
}
