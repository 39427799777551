class Address {
  id: number
  title: string
  street: string
  houseNumber: string
  zipcode: string
  city: string
  coordinates: { lat: number; lng: number }
  country: string

  constructor(address: any) {
    this.id = address.id
    this.title = address.attributes.title
    this.street = address.attributes.street_name
    this.houseNumber = address.attributes.street_number
    this.zipcode = address.attributes.zipcode
    this.city = address.attributes.city
    this.coordinates = address.attributes.coordinate
    this.country = address.attributes.country.code.toLowerCase()
  }
}

export { Address }
