import { isProxy, toRaw } from 'vue'

class BasketItemProductResource {
  constructor(product) {
    this.hash = product.hash
    this.id = product.id
    this.product_set_id = product.product_set_id
    this.title = product.title
    this.subtitle = product.subtitle
    this.unit_price = product.unit_price
    this.amount = product.amount
    this.price_before_discount = product.price_before_discount
    this.discount = product.discount
    this.price_after_discount = product.price_after_discount
    this.sub_product_groups = product.sub_product_groups
    this.instructions = product.instructions
  }

  static fromProductResource(product, productSetId, selectedAttributes) {
    if (product === undefined) return

    // after sync from the store, it's already a BasketItemProductResource
    if (
      product instanceof BasketItemProductResource ||
      // product isn't BasketItemProductResource, but it is :)
      Object.keys(product).includes('sub_product_groups')
    ) {
      return product
    }

    /** internal */
    function findSubProduct(product, productId, subProductId) {
      // this is a checkbox attribute
      // example: { 104: false }
      // so when subProductId is a proxy, it's a checkbox
      if (isProxy(subProductId)) {
        subProductId = toRaw(subProductId)

        const subProducts = []

        if (typeof subProductId === 'object') {
          const foundAttribute = product.attributes?.find(
            (item) => item.id === Number(productId)
          )

          for (const property in subProductId) {
            if (subProductId[property] === true) {
              const subProductItem = foundAttribute.subProducts?.find((item) => item.id === Number(property)) || {}

              subProducts.push({
                id: subProductItem.id,
                amount: 1,
                title: subProductItem.title,
                unit_price: subProductItem.price,
              })
            }
          }
        }

        return {
          id: Number(productId),
          sub_products: subProducts,
        }
      }

      const subProductItem =
        product.attributes
          ?.find((item) => item.id === Number(productId))
          ?.subProducts.find(
            (item) => item.id === Number(subProductId)
          ) || {}

      return {
        id: Number(productId),
        sub_products: [
          {
            id: subProductItem.id,
            amount: 1,
            title: subProductItem.title,
            unit_price: subProductItem.price,
          },
        ],
      }
    }

    this.hash = product.hash
    this.id = product.id
    this.product_set_id = productSetId
    this.title = product.title
    this.unit_price = product.price
    this.discount = product.discount ?? 0
    this.sub_product_groups = Array.from(selectedAttributes)
      .map(attribute => (findSubProduct(product, attribute[0], attribute[1])))
      .filter(attribute => attribute !== null)

    return this
  }
}

export {
  BasketItemProductResource
}
