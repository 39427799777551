import * as cities from './AllCities'
import * as categories from './AllCategories'
import * as thanks from './Thanks'

export const namespaced = true

export const modules = {
  cities,
  categories,
  thanks,
}
